import { Grid, Radio, RadioGroup, Box, Typography } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import CommonGrid from "../../../../components/CommonGrid"
import oeeService from "../../../../services/oeeService";
import { AuthContext } from "../../../../context/AuthContextProvider";
import { StatusContext } from "../../../../context/StatusContextProvider";
import { PlantContext } from "../../../../context/PlantContextProvider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { DGDateInput } from "../../../../components/shared/DGDateInput";
import {
    format, startOfWeek, startOfMonth, endOfMonth, endOfWeek,
    startOfDay, endOfDay
} from 'date-fns'
import { DGDrawer } from "../../../../components/DGDrawer";
import PrdnLossSummary from "./oee_prdnLosses_summary";


export default function ProductionLossesList() {
    const [rows, setRows] = useState([]);
    const [formOpen, setFormOpen] = useState(false);
    const { currentUser } = useContext(AuthContext);
    const { setStatus } = useContext(StatusContext);
    const { globalPlant } = useContext(PlantContext);
    const [startDate, setstartDate] = useState(new Date());
    const [endDate, setendDate] = useState(new Date());
    const [dateDepth, setDateDepth] = useState(3);
    const [selectedRow, setSelectedRow] = useState([]);



    /*     useEffect(() => {
            reloadGridData()
        }, []);
     */
    useEffect(() => {
        reloadGridData()
    }, [startDate, endDate]);


    useEffect(() => {
        switch (dateDepth) {
            case 1:
                const now = new Date();
                setstartDate(startOfDay(now));
                setendDate(endOfDay(now));

                break;

            case 2:
                setstartDate(startOfWeek(new Date(), { weekStartsOn: 1 }))
                if (endOfWeek(new Date(), { weekStartsOn: 1 }) > new Date()) {
                    setendDate(endOfDay(new Date()))
                } else {
                    setendDate(endOfWeek(new Date(), { weekStartsOn: 1 }))
                }
                break;

            case 3:
                setstartDate(startOfMonth(new Date()))

                if (endOfMonth(new Date(), { weekStartsOn: 1 }) > new Date()) {
                    setendDate(endOfDay(new Date()))
                } else {
                    setendDate(endOfMonth(new Date(), { weekStartsOn: 1 }))
                }

                break;

            default:

                // setdataLabel("User Defined");

                break;
        }

    }, [dateDepth]);


    function reloadGridData() {
        oeeService.getOEETable({
            client_id: currentUser.client_id,
            plant_id: globalPlant?.globalPlant?.id,
            start_date: startDate,
            end_date: endDate,
        })
            .then((res) => {
                setRows(res?.data);

            });
    }

    function onRowSelection(data) {
        const temp1 = data
        setSelectedRow(temp1[0]);
        if (temp1[0]?.production_planned_time == 0) {
            return;
        }
        setFormOpen(true);

    }


    const columns = [

        {
            field: "serial_number",
            headerName: "SI No",
            valueGetter: "node.rowIndex + 1",
            filter: "agTextColumnFilter",
            maxWidth: 80,
        },

        {
            field: "machine_detials",
            headerName: "Machine",
            filter: "agTextColumnFilter",
            flex: 1,
            valueGetter: (params) => {
                return params.data?.machine_number + " - " + params.data?.machine_name ?? ""
            }

        },

        {

            field: "oee_index",
            headerName: "OEE",
            filter: "agTextColumnFilter",
            flex: 1,
            valueGetter: (params) => {
                const qualityIndex = params.data?.quality_index;
                const availabilityIndex = params.data?.availability_index;
                return qualityIndex !== undefined && qualityIndex !== null
                    ? `${(qualityIndex * availabilityIndex * 100).toFixed(2)}%`
                    : "";
            }
        },
        {
            field: "availability_index",
            headerName: "Availability",
            filter: "agTextColumnFilter",
            flex: 1,
            valueGetter: (params) => {
                const availabilityIndex = params.data?.availability_index;
                return availabilityIndex !== undefined && availabilityIndex !== null
                    ? `${(availabilityIndex * 100).toFixed(1)}%`
                    : "";
            }
        },

        {

            field: "performance_index",
            headerName: "Performance",
            filter: "agTextColumnFilter",
            flex: 1,
            valueGetter: (params) => {
                const performance_index = params.data?.performance_index;
                return performance_index !== undefined && performance_index !== null
                    ? `${(performance_index * 100).toFixed(1)}%`
                    : "";
            }

        },


        {

            field: "quality_index",
            headerName: "Quality",
            filter: "agTextColumnFilter",
            flex: 1,
            valueGetter: (params) => {
                const qualityIndex = params.data?.quality_index;
                return qualityIndex !== undefined && qualityIndex !== null
                    ? `${(qualityIndex * 100).toFixed(2)}%`
                    : "";
            }

        },


        {

            field: "data_update",
            headerName: "Data avaiability",
            filter: "agTextColumnFilter",
            flex: 1,
            valueGetter: (params) => {
                const data_update = params.data?.data_update;
                return data_update !== undefined && data_update !== null && data_update !== 0
                    ? `${(data_update * 100).toFixed(1)}%`
                    : "";
            }

        }
    ]


    return (

        <Grid container spacing={3}>

            <Grid container spacing={2}
                style={{
                    "padding": "5px",
                    "margin-top": "10px",
                    "margin-bottom": "10px"
                }}>

                <Grid item xs={5} container justifyContent="center" alignItems="flex-left">
                    <RadioGroup row
                        style={{ gap: '12px' }}
                        value={dateDepth.toString()}
                        onChange={(event) => {
                            const newValue = parseInt(event.target.value);
                            setDateDepth(newValue);
                        }}
                    >
                        <FormControlLabel value="1" control={<Radio />} label="Day" />
                        <FormControlLabel value="2" control={<Radio />} label="Week" />
                        <FormControlLabel value="3" control={<Radio />} label="Month" />
                        <FormControlLabel value="4" control={<Radio />} label="User Defined" />
                    </RadioGroup>

                </Grid>

                <Grid item xs={7} container alignItems="flex-left">
                    <Grid container spacing={2}>

                        <Grid item xs={4}>
                            <DGDateInput
                                type="date"
                                label="Start Date"
                                value={startDate}
                                onChange={(date) => {
                                    setstartDate(startOfDay(date))
                                    setDateDepth(4);
                                }}
                                disabled={dateDepth < 4}
                            />
                        </Grid>
                        <Grid item xs={4}>

                            <DGDateInput
                                type="date"
                                label="End Date"
                                value={endDate}
                                onChange={(date) => {
                                    setendDate(endOfDay(date));
                                }}
                                disabled={dateDepth < 4}
                            />
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>



            <Grid item xs={12}>
                <CommonGrid
                    columns={columns}
                    rows={rows}
                    needExport={true}
                    onRowSelect={onRowSelection}

                />


            </Grid>

            <DGDrawer

                Component={PrdnLossSummary}
                isOpen={formOpen}
                drawerWidth={1000}
                setOpen={(state) => {
                    setFormOpen(false);
                }}
                title={`Loss Summary : ${selectedRow?.machine_name} - ${selectedRow?.machine_number}`}
                subtitle={`${format(new Date(startDate), 'dd/MM/yy')} - ${format(new Date(endDate), 'dd/MM/yy')} -Data Availability : ${(selectedRow?.data_update * 100).toFixed(1)}%`}

                componentProps={{
                    startDate,
                    endDate,
                    client_id: currentUser?.client_id,
                    plant_id: globalPlant?.globalPlant?.id,
                    machine_id: selectedRow?.machine_id,
                    data: selectedRow
                }}


            />

        </Grid>
    )


}