import { Button, Grid, Card, CardContent, Typography } from "@material-ui/core";
import React from "react";
import { useContext, useEffect, useState } from "react";
import CommonGrid from "../../../components/CommonGrid";
import { StatusContext } from "../../../context/StatusContextProvider";
import AddIcon from "@material-ui/icons/Add";
import { DGDrawer } from "../../../components/DGDrawer";
import moment from "moment";
import productionInspection from "../../../services/productionInspection";
import { AuthContext } from "../../../context/AuthContextProvider";
import { PlantContext } from "../../../context/PlantContextProvider";
import { UnderInspectionForm } from "../../productionInspection/product-inspection/UnderInspection/UnderInspectionForm";
import { GembaProductInspect } from "./productInspection"
import { RequestForm } from "../../productionInspection/product-inspection/UnderInspection/RequestForm";
import { ResultRenderer } from "../../../components/cellRenderers/ResultRenderer";
import { format, parse, parseISO } from 'date-fns';




export default function GembaUnderInspectionOpen() {

    const [rows, setRows] = useState([]);
    const { setStatus } = useContext(StatusContext);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedRow_, setSelectedRow_] = useState(null);
    const [formOpen, setFormOpen] = useState(false);
    const [measureformOpen, setMeasureFormOpen] = useState(false);
    const { currentUser } = useContext(AuthContext);
    const { globalPlant } = useContext(PlantContext);
    const [subtitle, setSubtitle] = useState("");
    const [subtitle_, setSubtitle_] = useState("");
    const [measureOpen, setMeasureOpen] = useState(false);

    useEffect(() => {
        setStatus((prev) => ({
            ...prev,
            reloadGrid: reloadgridData,
            // callback: changeStatus,
        }));
        if (globalPlant && globalPlant?.globalPlant?.id) {
            reloadgridData();
        }
    }, []);


    useEffect(() => {
        reloadgridData()
    }, [globalPlant])

    function reloadgridData() {
        productionInspection
            .getInspectTablePlant({
                client_id: currentUser?.client_id,
                query_id: globalPlant?.globalPlant?.id,
                production_inspection_status: 4,
            })
            .then((res) => {
                setRows(res?.data ?? []);
            });
    }

    function onRowSelection(data) {
        setSelectedRow(data[0])
        setSubtitle(data[0].request_name ?? "");
    }


    function LoadRequestaForm(data) {
        //   setSubtitle(data?.request_name ?? "");
        setFormOpen(true);
    }

    function LoadMeasureForm(data) {
        setSubtitle_(data.request_name ?? "");
        setSelectedRow_(data);
        setMeasureFormOpen(true);

    }





    const columns = [
        {
            headerName: "Sl No.",
            valueGetter: "node.rowIndex + 1",
            resizable: true,
            floatingFilter: true,
            filter: "agTextColumnFilter",
            minWidth: 105,
            pinned: "left",
        },
        {
            field: "request_name",
            headerName: "Request Number",
            sortable: true,
            resizable: true,
            minWidth: "200",
            floatingFilter: true,
            autoHeight: true,
            filter: "agTextColumnFilter",
            cellRenderer: (params) => {
                return (
                    <Button
                        size="small"
                        color="primary"
                        onClick={() => LoadMeasureForm(params?.data)}>
                        {`${params?.data?.request_name}`}
                    </Button>
                );
            },
        },
        {
            field: "part_number",
            headerName: "Part Number",
            sortable: true,
            resizable: true,
            minWidth: "200",
            floatingFilter: true,
            autoHeight: true,
            filter: "agTextColumnFilter",
        },
        {
            field: "plan_name",
            headerName: "Plan Name",
            sortable: true,
            resizable: true,
            minWidth: "200",
            floatingFilter: true,
            autoHeight: true,
            filter: "agTextColumnFilter",
        },
        {
            field: "production_date",
            headerName: "Production Date",
            sortable: true,
            resizable: true,
            minWidth: "200",
            floatingFilter: true,
            autoHeight: true,
            filter: "agDateColumnFilter",
            // filterParams: filterParams,
            valueGetter: (params) =>
                moment(new Date(params?.data?.production_date)).format("DD/MM/YYYY"),
        },
        {
            field: "production_time",
            headerName: "Production Time",
            sortable: true,
            resizable: true,
            minWidth: "150",
            floatingFilter: true,
            autoHeight: true,
            filter: "agTextColumnFilter",
        },
        {
            field: "request_result",
            headerName: "Result",
            sortable: true,
            resizable: true,
            minWidth: "200",
            floatingFilter: true,
            autoHeight: true,
            cellRenderer: (params) => (
                <ResultRenderer value={params?.data?.request_result} />
            ),
        },
        {
            field: "batch_details",
            headerName: "Batch Details",
            sortable: true,
            resizable: true,
            minWidth: "200",
            floatingFilter: true,
            autoHeight: true,
            filter: "agTextColumnFilter",
        },

        {
            field: "productionDetails",
            headerName: "Production Record",
            sortable: true,
            resizable: true,
            minWidth: "200",

            cellRenderer: (params) => {
                if (!params?.data?.production_date) {
                    return null;
                }

                const formattedDate = format(parseISO(params.data.production_date), 'dd/MM/yy');
                const formattedTime = params.data.production_start_time
                    ? format(parse(params.data.production_start_time, 'HH:mm:ss', new Date()), 'HH:mm')
                    : '';

                return (
                    <>
                        <Typography variant="body2" style={{ marginBottom: '-5px' }}>
                            {`PREC/${params?.data?.record_counter}`}
                        </Typography>

                        <Typography variant="body2">
                            {formattedDate} {formattedTime}
                        </Typography>
                    </>
                );
            }

        },

    ];




    return (
        <>
            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <Card style={{ border: '1px solid #ccc' }}>
                        <CardContent>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<AddIcon />}
                                onClick={() => LoadRequestaForm()}
                            >
                                New Evaluation
                            </Button>
                        </CardContent>
                    </Card>

                </Grid>


                <Grid item xs={12}>
                    <CommonGrid
                        rows={rows}
                        columns={columns}
                        gridheight={115}
                        onRowSelect={onRowSelection}
                        needExport={false}
                    />
                </Grid>

                <DGDrawer
                    Component={RequestForm}
                    title="Production Inspection"
                    isOpen={formOpen}
                    setOpen={(state, reqID) => {
                        if (reqID) {
                            setFormOpen(state);
                            setSelectedRow_({ request_id: reqID });
                            setMeasureFormOpen(true);
                        } else {
                            setFormOpen(state);
                        }
                        reloadgridData();
                    }}
                    drawerWidth={1200}
                    componentProps={1}
                    needSave
                />

                <DGDrawer
                    Component={UnderInspectionForm}
                    title="Production Inspection "
                    isOpen={measureformOpen}
                    setOpen={(state) => {
                        setMeasureFormOpen(state);
                        reloadgridData();
                    }}
                    subtitle={subtitle}
                    componentProps={selectedRow_}
                    drawerWidth={1200}
                    needSave
                />
            </Grid>
        </>

    );
}
