import {
    Grid, Button,
    Box,
    Paper,
    Tab,
    Tabs,
    withStyles,
} from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import CommonGrid from "../../../../components/CommonGrid"
import { DGInput } from "../../../../components/shared/DGInput"
import studyInputService from "../../../../services/studyInputService";
import { DGSelect } from "../../../../components/shared/DGSelect";
import plantService from "../../../../services/plantService";
import { AuthContext } from "../../../../context/AuthContextProvider";
import oeeService from "../../../../services/oeeService";
import { create } from "jss";

export default function StdLossesListAdd(
    { Footer = () => { },
        closeForm = () => { },
    }
) {

    const [rows, setRows] = useState([]);
    const [plantOptions, setPlantOptions] = useState([]);
    const [lossType, setLossType] = useState([]);
    const [selectedLossType, setSelectedLossType] = useState("");
    const { currentUser } = useContext(AuthContext);
    const [gridApi, setGridApi] = useState(null);
    const { v4: uuidv4 } = require("uuid");



    function onGridReady(params) {
        setGridApi(params?.api);
    }


    useEffect(() => {
        studyInputService.getAllStudies()
            .then((res) => {
                const data = res?.data?.data
                const data2 = data.filter((item) => item.group === "OEE" && item.id !== 15003)
                setLossType(data2);
            });
        plantService.getPlants(currentUser.client_id)
            .then((res) => {
                const temp = res?.data?.data ?? [];
                const temp1 = temp.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
                setPlantOptions(temp1);
            });

        setRows(generateBlankRows(10));
    }, []);



    const generateBlankRows = (numRows) => {
        return Array.from({ length: numRows }, () => ({}));
    };

    function addRow(numRows) {
        const newRows = generateBlankRows(numRows);
        setRows((prevRows) => [...prevRows, ...newRows]);
    }

    const CheckboxRenderer = (props) => {
        const isChecked = props.value === 1;
        const handleChange = (event) => {
            props.setValue(event.target.checked ? 1 : 0);
        };
        return (<input
            type="checkbox"
            checked={isChecked}
            onChange={handleChange}
        />)
    };

    const static_columns = [

        {
            field: "serial_number",
            headerName: "SI No",
            valueGetter: "node.rowIndex + 1",
            filter: "agTextColumnFilter",
            maxWidth: 80,
        },


        {
            headerName: "Description",
            field: "loss_description",
            filter: "agTextColumnFilter",
            minWidth: 250,
            editable: true,


        }
    ]


    const plant_columns = [];
    for (let i = 0; i < plantOptions.length; i++) {
        plant_columns.push({
            field: plantOptions[i].id,
            headerName: plantOptions[i].name,
            maxWidth: 175,
            resizable: true,
            editable: true,
            cellRenderer: CheckboxRenderer

        });
    }


    const columns = [...static_columns, ...plant_columns]


    function handleSubmit() {

        let data = []
        let splitData = [];
        gridApi.forEachNode((node) => {
            if (node.data && node.data.loss_description != null) {
                data.push({
                    ...node.data,
                    client_id: currentUser.client_id,
                    loss_id: uuidv4(),
                    loss_type: selectedLossType.id
                })
            };
        })

        splitData = data.flatMap(item => {
            const commonFields = {
                client_id: item.client_id,
                loss_description: item.loss_description,
                loss_id: item.loss_id,
                loss_type: item.loss_type,
                created_by: currentUser.id
            };

            return Object.keys(item)
                .filter(key => /^[0-9a-fA-F-]{36}$/.test(key) && item[key] === 1)
                .map(uuidKey => ({
                    plant_id: uuidKey,
                    ...commonFields
                }));
        });

        oeeService.createLossList(splitData)
            .then((res) => {
                closeForm();


            })


    }




    return (

        <Grid container spacing={3}>



            <Grid item xs={6}>
                <DGSelect
                    label="Loss Name"
                    value={selectedLossType}
                    options={lossType}
                    getOptionLabel={(option) => option.study}
                    onChange={(e) => { setSelectedLossType(e) }}
                    disabled={selectedLossType}
                />

            </Grid>

            <Grid
                xs={6}
                style={{
                    marginTop: "3px",
                    display: "flex",
                    justifyContent: "center"
                }}
            >
                <Button
                    size="large"
                    variant="outlined"
                    color="primary"
                    style={{ padding: "5px 70px" }}
                    onClick={() => addRow(5)}
                >
                    Add 5 Blank Rows
                </Button>
            </Grid>

            <Grid item xs={12}>
                <CommonGrid
                    columns={columns}
                    rows={rows}
                    setRows={setRows}
                    title="Losses"
                    needExport={false}
                    onGridReady={onGridReady}

                />
            </Grid>

            <Footer onSave={handleSubmit} />

        </Grid>
    )


}