import {
    Box, Button, Grid, makeStyles, Typography,
    AppBar, Divider, Toolbar, Checkbox, FormControlLabel
} from "@material-ui/core";
import { useFormik } from "formik";
import React, { useState, useEffect, useContext } from "react";
import { array, object, string, number } from "yup";

import { DGInput } from "../../../components/shared/DGInput";
import { DGSelect } from "../../../components/shared/DGSelect";
import { DGDateInput } from "../../../components/shared/DGDateInput";
import { DGDateTimePicker } from "../../../components/shared/DGDateTimePicker";
import { AlertContext } from "../../../context/AlertContextProvider";
import { AuthContext } from "../../../context/AuthContextProvider";
import plantService from "../../../services/plantService"
import CommonGrid from "../../../components/CommonGrid";
import { PlantContext } from "../../../context/PlantContextProvider";
import qualityAnalyticsService from "../../../services/qualityAnalyticsService";
import oeeService from "../../../services/oeeService";
import { format } from "date-fns";
import { each } from "highcharts";



export function ProductionRecordFormView({
    Footer = () => null,
    closeForm = () => { },
    componentProps = null

}) {
    const { v4: uuidv4 } = require("uuid");
    const { currentUser } = useContext(AuthContext);
    const [gridApi, setGridApi] = useState(null);
    const [rows, setRows] = useState([])
    const { globalPlant } = useContext(PlantContext)



    // console.log(componentProps)

    let form = useFormik({
        initialValues: {

            machine: (componentProps?.machine_number + " " + componentProps.machine_name) ?? null,
            part: (componentProps?.part_number + " " + componentProps.part_name) ?? null,
            operator: (componentProps?.operator_eid + " " + componentProps.operator_name) ?? null,
            productionDate: new Date(componentProps?.production_date),
            productionStartTime: componentProps?.production_start_time ?? "",
            productionEndTime: componentProps?.production_end_time ?? "",
            vsSelected: "",
            quantity: componentProps?.production_quantity ?? "",
            rejections: componentProps?.production_rejections ?? "",
            partDayTarget: null,
            tool: (componentProps?.tool_name ?? "") + " " + (componentProps?.tool_number ?? "") ?? null,
            shots: componentProps?.tool_shots ?? "",

            cumTime: 0,
            planCumTime: 0,
            cumQuantity: 0,


            oeeQuantity: "",



        },


        //  onSubmit: saveForm

    });


    useEffect(() => {

        if (componentProps) {
            getPartDayTarget()

            oeeService.getPrdouctionIDLoss({
                production_record_id: componentProps?.id
            })

                .then((res) => {
                    const temp = res?.data ?? []
                    setRows(temp)
                });
        }
    }, [componentProps])



    useEffect(() => {

        let temp1 = 0
        let temp2 = 0


        if (rows.length > 0) {
            rows.forEach((item) => {
                if (item.loss_type_id === 15003) {
                    temp1 += item.loss_time;
                } else {
                    temp2 += item.loss_time;
                }
            });
        }

        form.setFieldValue("cumQuantity", temp1);
        form.setFieldValue("cumTime", temp2);
    }, [rows])

    let form2 = useFormik({

        initialValues: {

            oeeLossSelected: null,
            lossQuantity: '',
            plannedLoss: false

        }

    });


    function onGridReady(params) {
        setGridApi(params.api);

    }


    const columns = [


        {
            field: "loss_type",
            headerName: "Category",
            flex: 1,
            editable: true,
            resizable: true,
            minWidth: 150,

        },

        {
            field: "loss_description",
            headerName: " Description",
            flex: 1,
            editable: true,
            resizable: true,
            minWidth: 150,

        },

        {
            field: "units",
            headerName: "Units",
            flex: 1,
            editable: true
        },

        {
            field: "loss_time",
            headerName: " Time/Pieces",
            flex: 1,
            editable: true

        },

        {
            field: "planned_loss",
            headerName: "Planned",
            flex: 1,
            cellRenderer: (params) => {
                return params.value == 1 ? "✔️" : "";
            },
        }

    ];



    function getPartDayTarget() {
        oeeService.getPartDayTarget({
            plant_id: globalPlant?.globalPlant?.id,
            client_id: currentUser.client_id,
            part_id: componentProps?.part_id,
            start_time: form.values.productionStartTime,
            end_time: form.values.productionEndTime,
            machine_id: componentProps?.machine_id,
            parts_produced: form.values.quantity,
        })
            .then((res) => {
                const temp = res?.data ?? []
                form.setFieldValue("partDayTarget", temp);
                form.setFieldValue("oeeQuantity", temp?.target_parts)
            });
    }




    return (

        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>

                    {/* Production Date and time */}
                    <Grid container xs={12} direction="row" spacing={3}
                        style={{ "margin-bottom": "3px" }}>
                        <Grid item xs={3}>
                            <DGInput
                                label="Production Date"
                                value={format(form.values.productionDate, "dd/MM/yyyy")}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <DGInput
                                label=" Start Time"
                                value={form.values.productionStartTime}

                            />
                        </Grid>
                        <Grid item xs={2}>
                            <DGInput
                                label=" End Time"
                                value={form.values.productionEndTime}

                            />
                        </Grid>

                        <Grid item xs={5}>
                            <DGInput
                                label="Value Stream"
                                value={form.values.vsSelected}

                            />
                        </Grid>
                    </Grid>

                    {/*  Machine and Part */}
                    <Grid container xs={12} direction="row" spacing={3}
                        style={{ "margin-bottom": "3px" }}>


                        <Grid item xs={4}>
                            <DGInput
                                id="machine"
                                label="Machine"
                                value={form.values.machine}

                            />
                        </Grid>

                        <Grid item xs={3}>
                            <DGInput
                                id="operator"
                                label="Operator"
                                value={form.values.operator}

                            />
                        </Grid>

                        <Grid item xs={5}>
                            <DGInput
                                id="part"
                                label="Part"
                                value={form.values.part}

                            />
                        </Grid>
                    </Grid>

                    {/*Production Quantity and Rejections*/}
                    <Grid container xs={12} direction="row" spacing={3}
                        style={{ "margin-bottom": "3px" }}>


                        <Grid item xs={5}>
                            <DGInput
                                id="form.values.tool"
                                label="Form Tool"
                                value={form.values.tool}

                            />
                        </Grid>


                        <Grid item xs={2}>
                            <DGInput
                                label="Shots"
                                value={form.values.shots}

                            />
                        </Grid>



                        <Grid item xs={3}>
                            <DGInput
                                id="quantity"
                                label="Quantity"
                                value={form.values.quantity}
                            />
                        </Grid>


                        <Grid item xs={2}>
                            <DGInput
                                id="rejections"
                                value={form.values.rejections}
                            />
                        </Grid>

                    </Grid>

                    {/* OEE */}
                    <Grid container xs={12} direction="row" spacing={3}
                        style={{ "margin-bottom": "3px" }}>



                        <Grid item xs={3} >
                            <DGInput
                                id="target_production"
                                label="Target Quantity"
                                value={form.values?.oeeQuantity ?? ""}
                            />
                        </Grid>


                        <Grid item xs={3} >
                            <DGInput
                                id="loss"
                                label="Loss In minuted"
                                value={form.values?.partDayTarget?.time_loss_min ?? ""}
                            />
                        </Grid>

                        <Grid item xs={3} >
                            <DGInput
                                id="loss"
                                label="Loss Un-classified"
                                value={form.values?.partDayTarget?.time_loss_min - form.values.cumTime}
                            />
                        </Grid>

                        <Grid item xs={3} >
                            <DGInput
                                id="loss"
                                label="Defects Un-classified"
                                value={form.values.rejections - form.values.cumQuantity}
                            />
                        </Grid>

                    </Grid>



                    {/* Grid */}
                    <Grid container xs={12} direction="row" spacing={3}
                        style={{ "margin-bottom": "3px" }}>

                        <Grid item md={12} sm={12} lg={12}>
                            <CommonGrid
                                rows={rows}
                                columns={columns}
                                needExport={false}
                                gridheight={370}
                                onGridReady={onGridReady}
                            />
                        </Grid>

                    </Grid >

                </Grid>




            </Grid >



        </>

    );

}
export default ProductionRecordFormView;