import { Grid, Radio, RadioGroup, Box, Typography } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import CommonGrid from "../../../../components/CommonGrid"
import oeeService from "../../../../services/oeeService";
import { AuthContext } from "../../../../context/AuthContextProvider";
import { StatusContext } from "../../../../context/StatusContextProvider";
import { PlantContext } from "../../../../context/PlantContextProvider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { DGDateInput } from "../../../../components/shared/DGDateInput";
import { DGDrawer } from "../../../../components/DGDrawer";
import { deepOrange, orange, red, yellow, green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { StackedBarGraph } from "../../../../components/graphs/StackedBarGraphs";
import { min, sub, format } from "date-fns";



export default function PrdnLossSummary(
    {
        Footer = () => { },
        closeForm = () => { },
        componentProps = null
    }
) {

    const { currentUser } = useContext(AuthContext);
    const { setStatus } = useContext(StatusContext);
    const classes = useStyles();
    const [rows, setRows] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [filteredRows, setFilteredRows] = useState([]);
    const [graphOptions, setGraphOptions] = useState([])

    useEffect(() => {
        oeeService.getOEELossesForOEE({
            client_id: componentProps.client_id,
            start_date: componentProps.startDate,
            end_date: componentProps.endDate,
            plant_id: componentProps.plant_id,
            machine_id: componentProps.machine_id,

        })
            .then((res) => {
                setRows(res?.data ?? [])

                const aggregatedData = Object.values(
                    res.data.reduce((acc, item) => {
                        if (!acc[item.loss_id]) {
                            acc[item.loss_id] = {
                                loss_description: item.loss_description,
                                pareto_data: 0
                            };
                        }
                        acc[item.loss_id].pareto_data += item.pareto_data;
                        return acc;
                    }, {})
                ).sort((a, b) => b.pareto_data - a.pareto_data);


                setGraphOptions({
                    ...graphOptions,
                    title: {
                        text: `Production Loss : ${componentProps?.data?.machine_number} - ${componentProps?.data?.machine_name}`,
                    },
                    subtitle: {
                        text:
                            `${format(new Date(componentProps.startDate,), 'dd/MM/yy')} - ${format(new Date(componentProps.endDate,), 'dd/MM/yy')} - Data Avaialability : ${(componentProps.data?.data_update * 100).toFixed(1)} % `
                    },


                    series: [
                        {
                            // type: "bar",
                            xKey: "loss_description",
                            yKey: "pareto_data",
                            type: "column",
                        },
                    ],

                    data: aggregatedData ?? [],


                    legend: {
                        enabled: false,
                    },
                });

            })


    }, [componentProps])


    const handleFilterApplied = (event) => {
        const allDisplayedNodes = event.api.getRenderedNodes();
        const displayedData = allDisplayedNodes.map(node => node.data);
        setFilteredRows(displayedData);

        const aggregatedData = Object.values(
            displayedData.reduce((acc, item) => {
                if (!acc[item.loss_id]) {
                    acc[item.loss_id] = {
                        loss_description: item.loss_description,
                        pareto_data: 0
                    };
                }
                acc[item.loss_id].pareto_data += item.pareto_data;
                return acc;
            }, {})
        ).sort((a, b) => b.pareto_data - a.pareto_data);

        setGraphOptions({
            ...graphOptions,
            data: aggregatedData ?? [],
        });
    };

    const statuses = [
        {
            title: 'OEE',
            background: orange[100],
            id: 101,
            value: componentProps?.data?.availability_index
                ? (componentProps.data.availability_index *
                    componentProps.data.performance_index *
                    componentProps.data.quality_index
                    * 100).toFixed(2) + "%"
                : ""
        },
        {
            title: 'Availability Index',
            background: yellow[100],
            id: 101,
            value: componentProps?.data?.availability_index
                ? (componentProps.data.availability_index * 100).toFixed(1) + "%"
                : ""
        },
        {
            title: 'Performance Index',
            background: green[100],
            id: 101,
            value: componentProps?.data?.performance_index
                ? (componentProps.data.performance_index * 100).toFixed(1) + "%"
                : ""
        },
        {
            title: 'Quality Index',
            background: red[100],
            id: 101,
            value: componentProps?.data?.quality_index
                ? (componentProps.data.quality_index * 100).toFixed(1) + "%"
                : ""
        }


    ];

    const StatusGridItem = ({ title, background, value, onClick, selected }) => (
        <Grid item container direction="column" xs={3}
            className={`${classes.common} ${selected ? classes.selected : ''}`}
            //  onClick={onClick}
            style={{ background, gap: '3px' }}
        >
            <Typography align="left" variant="h6" className={classes.header}>
                {title}
            </Typography>
            <Typography align="right" className={classes.count} variant="h2">
                {value}
            </Typography>
        </Grid>
    );

    const columns = [

        {
            headerName: "Sl No.",
            valueGetter: "node.rowIndex + 1",
            maxWidth: 100,
            pinned: "left",
        },

        {
            field: "Part",
            headerName: "Part Description",
            flex: 1,
            minWidth: 250,
            filter: "agMultiColumnFilter",
            valueGetter: (params) => {
                return params.data?.part_number + " - " + params.data?.part_name ?? ""
            }

        },

        {
            field: "loss_type",
            headerName: "Category",
            flex: 1,
            editable: true,
            resizable: true,
            minWidth: 150,
            filter: "agMultiColumnFilter",

        },

        {
            field: "loss_description",
            headerName: " Description",
            flex: 1,
            editable: true,
            resizable: true,
            minWidth: 300,

        },

        {
            field: "loss_sum",
            headerName: " Time/Pieces",
            flex: 1,
            editable: true

        },

        {
            field: "is_planned",
            headerName: "Planned",
            flex: 1,
            cellRenderer: (params) => {
                return params.value ? "✔️" : "";
            },
        },

        {
            field: "units",
            headerName: "Units",
            flex: 1,
            editable: true
        },

    ];

    function onGridReady(params) {
        setGridApi(params.api);
    }

    return (

        <Grid container spacing={2}>

            <Grid container spacing={2} justifyContent="space-evenly" alignItems="center">
                {statuses.map((status, index) => (
                    <StatusGridItem
                        key={index}
                        title={status.title}
                        value={status.value}
                        background={status.background}
                    //selected={selected === status.id}

                    />
                ))}
            </Grid>

            <Grid item xs={12}
                style={{
                    "margin-top": "20px",
                    "height": "400px"

                }
                }>
                <StackedBarGraph
                    options={graphOptions}
                />

            </Grid>


            <Grid item xs={12}>
                <CommonGrid
                    columns={columns}
                    rows={rows}
                    gridheight={300}
                    needExport={false}
                    onGridReady={onGridReady}
                    onFilterChanged={handleFilterApplied}
                />
            </Grid>



        </Grid>
    )

}

const useStyles = makeStyles({
    common: {
        color: "#fff",
        borderRadius: 25,
        cursor: "pointer",
        //margin: 5,
        width: "95%",
        border: "8px solid #FDFEFE",
    },
    header: {
        color: "#000",
        padding: 5,
        // paddingBottom: 3,
    },
    count: {
        color: "#000",
        padding: 5,
        paddingTop: 0,
    },
    selected: {

        backgroundColor: "#fff !important",
    },

});