import {
    Grid, Button,
    Radio, RadioGroup, Box, Typography
} from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import CommonGrid from "../../../../components/CommonGrid"
import oeeService from "../../../../services/oeeService";
import { AuthContext } from "../../../../context/AuthContextProvider";
import { StatusContext } from "../../../../context/StatusContextProvider";
import { PlantContext } from "../../../../context/PlantContextProvider";
import { format, startOfWeek, startOfMonth, endOfMonth, endOfWeek, min } from 'date-fns'
import { Functions } from "@material-ui/icons";
import { DGDrawer } from "../../../../components/DGDrawer";
import OEELossSummary from "./oee_loss_summary";



export default function OEELossList() {
    const [rows, setRows] = useState([]);
    const [formOpen, setFormOpen] = useState(false);
    const { currentUser } = useContext(AuthContext);
    const { setStatus } = useContext(StatusContext);
    const { globalPlant } = useContext(PlantContext);
    const [startDate, setstartDate] = useState(new Date());
    const [endDate, setendDate] = useState(new Date());
    const [dateDepth, setDateDepth] = useState(0);
    const [prdnRecordView, setPrdnRecordView] = useState(null);

    useEffect(() => {
        reloadGridData()
    }, [dateDepth]);

    useEffect(() => {
        reloadGridData()
    }, [startDate, endDate]);



    useEffect(() => {

        switch (dateDepth) {
            case 1:
                setstartDate(new Date())
                setendDate(new Date())

                break;

            case 2:
                setstartDate(startOfWeek(new Date(), { weekStartsOn: 1 }))
                if (endOfWeek(new Date(), { weekStartsOn: 1 }) > new Date()) {
                    setendDate(new Date())
                } else {
                    setendDate(endOfWeek(new Date(), { weekStartsOn: 1 }))
                }
                break;

            case 3:
                setstartDate(startOfMonth(new Date()))
                // setdataLabel("Month");

                if (endOfMonth(new Date(), { weekStartsOn: 1 }) > new Date()) {
                    setendDate(new Date())
                } else {
                    setendDate(endOfMonth(new Date(), { weekStartsOn: 1 }))
                }

                break;

            default:

                // setdataLabel("User Defined");

                break;
        }



    }, [dateDepth]);


    function reloadGridData() {
        oeeService.getLossProductionTable({
            client_id: currentUser.client_id,
            plant_id: globalPlant?.globalPlant?.id,

        })
            .then((res) => {
                setRows(res.data);
            });
    }

    function loadViewForm(data) {
        setPrdnRecordView(true);
    }


    const columns = [

        {
            field: "serial_number",
            headerName: "SI No",
            valueGetter: "node.rowIndex + 1",
            filter: "agTextColumnFilter",
            maxWidth: 80,
        },



        {
            field: "loss_type",
            headerName: "Loss Category",
            minWidth: 150,
            flex: 1,

        },

        {
            field: "loss_description",
            headerName: "Loss Description",
            minWidth: 200,
            flex: 1,
        },

        {
            field: "loss_duration",
            headerName: "Loss Duration",
            flex: 1,
            minWidth: 150,

        },


        {
            field: "is_planned",
            headerName: "Planned loss",
            flex: 1,
            valueFormatter: (params) => {
                return params.value === 1 ? '✔️' : '';
            }


        },

        {
            field: "part_number",
            headerName: "Part Details",
            filter: "agTextColumnFilter",
            minWidth: 250,
            valueGetter: (params) => {
                return params.data?.part_number + " - " + params.data?.part_name ?? ""
            }

        },

        {
            field: "machine_detials",
            headerName: "Machine",
            filter: "agTextColumnFilter",
            minWidth: 250,
            flex: 1,
            valueGetter: (params) => {
                return params.data?.machine_number + " - " + params.data?.machine_name ?? ""
            }

        },

        {
            field: "record_counter",
            headerName: "Record Number",
            flex: 1,
            minWidth: 150,

            cellRenderer: (params) => {
                return (
                    /*      <Button
                             size="small"
                             color="primary"
                             onClick={() => {
                                 //     loadViewForm(params?.data);
                             }}
                         >
                             {'PREC/' + params?.data?.record_counter}
                         </Button> */

                    'PREC/' + params?.data?.record_counter
                );
            },

        },

        {
            field: "production_date",
            headerName: "Date:Production",
            flex: 1,
            editable: false,
            resizable: true,
            // floatingFilter: true,
            //  filter: "agMultiColumnFilter",
            minWidth: 150,
            sort: "desc",
            sortable: true,
            valueGetter: (params) => {
                return new Date(params?.data?.production_date);
            },
            valueFormatter: (params) => {
                return format(new Date(params.value), 'dd/MM/yyyy');
            }
        }
        ,

        {
            field: "production_start_time",
            headerName: "Start Time:Production",
            flex: 1,
            editable: false,
            resizable: true,
            //   floatingFilter: true,
            //   filter: "agMultiColumnFilter",
            minWidth: 150,

            valueGetter: (params) => {
                const productionStartTime = params?.data?.production_start_time;
                if (productionStartTime) {
                    const [hours, minutes] = productionStartTime.split(':');
                    return `${hours}:${minutes}`;
                }
                return '';
            }

        },
        {
            field: "production_end_time",
            headerName: " End Time : Production",
            flex: 1,
            editable: false,
            resizable: true,
            //  floatingFilter: true,
            //  filter: "agMultiColumnFilter",
            minWidth: 150,
            valueGetter: (params) => {
                const productionEndTime = params?.data?.production_end_time;
                if (productionEndTime) {
                    const [hours, minutes] = productionEndTime.split(':');
                    return `${hours}:${minutes}`;
                }
                return '';
            }

        },





    ]


    function ButtonComponent() {
        return (
            <>


                <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<Functions />}
                    onClick={() => {
                        setFormOpen(true);
                    }}
                    style={{ marginLeft: 3 }}
                >
                    Summary
                </Button>

            </>
        );
    }

    return (

        <Grid container spacing={3}>

            <Grid item xs={12}>
                <CommonGrid
                    columns={columns}
                    rows={rows}
                    needExport={true}
                    gridheight={145}
                    ButtonComponent={ButtonComponent}

                />
            </Grid>

            <DGDrawer
                setOpen={() => setFormOpen(false)}
                title="OEE Loss Summary"
                Component={OEELossSummary}
                isOpen={formOpen}
                drawerWidth={1000}

            />

        </Grid>
    )


}