import http from "./httpService";


const createHolidayas = async (data) => {
    return await http.post("/company-holidays", data);
};


const getHolidays = (client_id) => {
    return http.get("/company-holidays-table", {
        params: {
            client_id: client_id,
        },
    });
};


const getShiftTable = (client_id) => {
    return http.get("/shift-management-table", {
        params: {
            client_id: client_id,
        },
    });
}


export default {
    createHolidayas,
    getHolidays,
    getShiftTable
};