import { Box, Grid, makeStyles, Button } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { DGDrawer } from "../../../components/DGDrawer";
import CommonGrid from "../../../components/CommonGrid";
import { PlantContext } from "../../../context/PlantContextProvider";
import AddIcon from "@material-ui/icons/Add";
import plantService from "../../../services/plantService";
import { AuthContext } from "../../../context/AuthContextProvider";
import { format } from 'date-fns';
import companySettings from "../../../services/companySettings";
import { StatusContext } from "../../../context/StatusContextProvider";
import { pl } from "date-fns/locale";


export default function ShiftManagementList() {
    const [rows, setRows] = useState([]);
    const [plantOptions, setPlantOptions] = useState([]);
    const { currentUser } = useContext(AuthContext);


    useEffect(() => {
        plantService.getPlants(currentUser.client_id)
            .then((res) => {
                const temp = res?.data?.data ?? [];
                const temp1 = temp.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
                setPlantOptions(temp1);
            });
    }, []);

    useEffect(() => {
        if (plantOptions.length === 0)
            return;
        companySettings.getShiftTable(currentUser.client_id)
            .then((res) => {
                setRows(res.data);
            });
    }, [plantOptions]);






    const static_columns = [

        {
            field: "serial_number",
            headerName: "SI No",
            valueGetter: "node.rowIndex + 1",
            filter: "agTextColumnFilter",
            maxWidth: 60,
        },

        {
            headerName: "Shift Name",
            field: "shift_name",
            minWidth: 120,
        },

    ]

    const plant_columns = [];
    for (let i = 0; i < plantOptions.length; i++) {
        const plantId = plantOptions[i].id;

        plant_columns.push({
            field: plantId,
            headerName: plantOptions[i].name,
            minWidth: 80,
            resizable: true,

        });
    }





    const columns = [...static_columns, ...plant_columns]

    return (

        <Grid container spacing={2}>
            <Grid item xs={12}>

                <CommonGrid
                    rows={rows}
                    columns={columns}
                    gridheight={115}

                />


            </Grid>


        </Grid>

    )


}