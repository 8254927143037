import {
    Box, Button, Grid, makeStyles, Typography, Chip,
    AppBar, Divider, Toolbar, Checkbox, FormControlLabel
} from "@material-ui/core";
import { useFormik } from "formik";
import React, { useState, useEffect, useContext } from "react";

import { DGInput } from "../../../components/shared/DGInput";
import { DGSelect } from "../../../components/shared/DGSelect";
import { AlertContext } from "../../../context/AlertContextProvider";
import { AuthContext } from "../../../context/AuthContextProvider";
import plantService from "../../../services/plantService"
import CommonGrid from "../../../components/CommonGrid";
import { PlantContext } from "../../../context/PlantContextProvider";
import qualityAnalyticsService from "../../../services/qualityAnalyticsService";
import oeeService from "../../../services/oeeService";
import { DeleteRenderer } from "../../../components/cellRenderers/DeleteRenderer";
import { format, max, set } from "date-fns";
import DialPadComponentOEE from "../../../components/DialPadComponentOEE";
import { transparentize } from "polished";
import errorLog from "../../../services/errorLog";




export function ProductionRecordFormEdit({
    Footer = () => null,
    closeForm = () => { },
    componentProps = null

}) {
    const { v4: uuidv4 } = require("uuid");
    const { currentUser } = useContext(AuthContext);
    const [gridApi, setGridApi] = useState(null);
    const [rows, setRows] = useState([])
    const { globalPlant } = useContext(PlantContext)
    const [fieldFlag, setFieldFlag] = useState(0);
    const [dailPad, setDailPad] = useState(true);
    const { updateAlert } = useContext(AlertContext);


    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            flexDirection: fieldFlag === 5 ? 'column' : 'row',
            justifyContent: 'left',
            flexWrap: 'wrap',
            gap: '10px',
            padding: '5px',
            width: 'fit-content',

            '& > *': {
                fontSize: '15px',
                padding: "15px",
                justifyContent: 'left',
                width: 'fit-content',
            },
        },
    }));

    const chipclasses = useStyles()

    let form = useFormik({
        initialValues: {

            machine: (componentProps?.machine_number + " " + componentProps.machine_name) ?? null,
            part: (componentProps?.part_number + " " + componentProps.part_name) ?? null,
            operator: (componentProps?.operator_eid + " " + componentProps.operator_name) ?? null,
            productionDate: new Date(componentProps?.production_date),
            productionStartTime: componentProps?.production_start_time ?? "",
            productionEndTime: componentProps?.production_end_time ?? "",
            vsSelected: "",
            quantity: componentProps?.production_quantity ?? "",
            rejections: componentProps?.production_rejections ?? "",
            partDayTarget: null,
            tool: (componentProps?.tool_name ?? "") + " " + (componentProps?.tool_number ?? "") ?? null,
            shots: componentProps?.tool_shots ?? "",
            cumTime: 0,
            planCumTime: 0,
            cumQuantity: 0,
            oeeQuantity: "",
            oeeChipOptions: [],

        },


        //  onSubmit: saveForm

    });


    useEffect(() => {

        if (componentProps) {
            getPartDayTarget()
            updateTable()
        }

    }, [componentProps])


    function updateTable() {
        oeeService.getPrdouctionIDLoss({
            production_record_id: componentProps?.id
        })
            .then((res) => {
                const temp = res?.data ?? []
                setRows(temp)

            });
        getOEELossOptions()

    }



    useEffect(() => {

        let temp1 = 0
        let temp2 = 0


        if (rows.length > 0) {
            rows.forEach((item) => {
                if (item.loss_type_id === 15003) {
                    temp1 += item.loss_time;
                } else {
                    temp2 += item.loss_time;
                }
            });
        }

        form.setFieldValue("cumQuantity", temp1);
        form.setFieldValue("cumTime", temp2);
    }, [rows])

    let form2 = useFormik({

        initialValues: {

            oeeLossSelected: null,
            lossQuantity: '',
            plannedLoss: false

        }

    });


    function onGridReady(params) {
        setGridApi(params.api);

    }


    const columns = [
        {
            cellRenderer: DeleteRenderer,
            maxWidth: 100,
            cellRendererParams: {
                onClick: (data, rowIndex) => {
                    removeDetailRow(rowIndex, data);
                },
            },
            resizable: true,
        },

        {
            field: "loss_type",
            headerName: "Category",
            flex: 1,
            editable: true,
            resizable: true,
            minWidth: 150,

        },

        {
            field: "loss_description",
            headerName: " Description",
            flex: 1,
            editable: true,
            resizable: true,
            minWidth: 150,

        },

        {
            field: "units",
            headerName: "Units",
            flex: 1,
            editable: true
        },

        {
            field: "loss_time",
            headerName: " Time/Pieces",
            flex: 1,
            editable: true

        },

        {
            field: "planned_loss",
            headerName: "Planned",
            flex: 1,
            maxWidth: 130,
            cellRenderer: (params) => {
                return params.value == 1 ? "✔️" : "";
            },
        }

    ];



    function getPartDayTarget() {
        oeeService.getPartDayTarget({
            plant_id: globalPlant?.globalPlant?.id,
            client_id: currentUser.client_id,
            part_id: componentProps?.part_id,
            start_time: form.values.productionStartTime,
            end_time: form.values.productionEndTime,
            machine_id: componentProps?.machine_id,
            parts_produced: form.values.quantity,
        })
            .then((res) => {
                const temp = res?.data ?? []
                form.setFieldValue("partDayTarget", temp);
                form.setFieldValue("oeeQuantity", temp?.target_parts)
            });
    }


    function handleMeasuredValue(value) {
        onAdd(value)
    }



    function onAdd(val) {
        const value = parseInt(val)
        form2.setFieldValue("lossQuantity", value);

        const data = [
            {
                id: uuidv4(),
                loss_description: form2.values.oeeLossSelected?.loss_description,
                loss_time: value,
                loss_id: form2.values.oeeLossSelected?.loss_id,
                loss_type: form2.values.oeeLossSelected?.loss_type,
                loss_type_id: form2.values.oeeLossSelected?.loss_type_id,
                plannedLoss: form2.values.plannedLoss,
                new: true,
                units: form2.values.oeeLossSelected.loss_type_id === 15003
                    ? "Quantity"
                    : "Minutes",
            }
        ];


        if (form2.values.oeeLossSelected.loss_type_id === 15003) {
            if ((form.values.cumQuantity + value) > form.values.rejections) {
                updateAlert({
                    open: true,
                    message: "This defect quantity cannot exceed Total defects",
                    type: "error",
                });
                return;
            } else {
                form.setFieldValue("cumQuantity", form.values.cumQuantity + value);
            }

        } else {
            if ((form.values.cumTime + value) > form.values?.partDayTarget?.time_loss_min) {
                updateAlert({
                    open: true,
                    message: "Loss time cannot exceed total loss time",
                    type: "error",
                });
                return;
            } else {
                form.setFieldValue("cumTime", form.values.cumTime + value);
                if (form2.values.oeeLossSelected.loss_type_id === 15002) {
                    const temp = form.values.partDayTarget?.total_time - (form.values.planCumTime * 60) - (value * 60)
                    form.setFieldValue("planCumTime", form.values.planCumTime + value)
                    // console.log("temp", temp, form.values.partDayTarget?.total_time, form.values.planCumTime, value)
                    const temp1 = Math.floor(temp / form.values.partDayTarget?.cycle_time)
                    form.setFieldValue("oeeQuantity", temp1)
                }


            }
        }
        setRows([...rows, ...data]);
        form2.resetForm()
    }

    function getOEELossOptions() {
        const masterLoss = []

        qualityAnalyticsService.getDefectMasterTable({
            client_id: currentUser?.client_id,
            plant_id: globalPlant?.globalPlant?.id
        })
            .then((res) => {
                const data1 = res?.data ?? [];
                const data = data1.map(item => ({
                    ...item,
                    loss_type_id: 15003,
                    loss_type: "Quality"
                }));
                masterLoss.push(...data.filter(list => list.enabled === 1));

            });

        oeeService.getOEELossList(currentUser.client_id)
            .then((res) => {
                masterLoss.push(...res.data)
            });

        form.setFieldValue("oeeLossOptions", masterLoss ?? []);
        form.setFieldValue("oeeChipOptions", masterLoss ?? []);

    }

    function removeDetailRow(rowIndex, data) {

        if (!data.new) {

            if (data.loss_type_id === 15003) {
                oeeService.updateProductionRejections(data.id, { enabled: 0 })
            } else {
                oeeService.updateProductionLoss(data.id, { enabled: 0 })
            }
        }
        else {
            const updatedGridData = [...rows];
            updatedGridData.splice(rowIndex, 1);
            setRows(updatedGridData);
            gridApi?.updateRowData({ remove: [data] });
        }

        if (data.loss_type_id === 15003) {
            form.setFieldValue("cumQuantity", form.values.cumQuantity - data.loss_time);
        } else {
            form.setFieldValue("cumTime", form.values.cumTime - data.loss_time)
        }

        updateTable()
    }

    function updateLoss() {
        saveDefects(componentProps?.id)
        saveLoss(componentProps?.id)

    }


    function saveDefects(tempID) {
        if (rows.length > 0) {
            let payloadD = []
            rows.forEach(exp => {
                if (exp.loss_type_id === 15003 && exp.new) {
                    payloadD.push({
                        id: exp.id,
                        plant_id: globalPlant?.globalPlant?.id,
                        client_id: currentUser?.client_id,
                        part_id: componentProps?.part_id,
                        production_record_id: tempID,
                        defect_master_id: exp.loss_id,
                        production_quantity: null,
                        defect_quantity: parseInt(exp.loss_time),
                        measurement_type: null,
                        basic_eval_id: null,
                        comments: null,
                        ps_project_id: null,
                        machine_id: componentProps?.machine_id,
                        machine_spindle_id: null,
                        production_date: null,
                        production_start_time: null,
                        production_end_time: null,
                        defect_incident_id: null,
                        operator_id: form.values.operator.operator_id,
                        enabled: 1
                    })
                }
            })

            qualityAnalyticsService.createDefectClassification(payloadD)
                .then((res) => {
                    updateAlert({
                        open: true,
                        message: "Defects recorded Successfully",
                        type: "success",
                    });
                    closeForm();
                })
                .catch((error) => {
                    updateAlert({
                        open: true,
                        message: "Failed to record defects",
                        type: "error",
                    });
                    let payload = {
                        id: uuidv4(),
                        error_description: error.message,
                        error_location: "createDefectClassification",
                        client_id: currentUser.client_id,
                        user_id: currentUser.id,
                        entity_id: null,
                    };
                    errorLog.createErrorLog(payload)

                });
        }
    }


    function saveLoss(tempID) {
        if (rows.length > 0) {
            let payload = []
            rows.forEach(exp => {
                if (exp.loss_type_id !== 15003 && exp.new) {
                    payload.push({
                        plant_id: globalPlant?.globalPlant?.id,
                        client_id: currentUser?.client_id,
                        part_id: componentProps?.part_id,
                        machine_id: componentProps?.machine_id,
                        production_record_id: tempID,
                        loss_type_id: exp.loss_type_id,
                        loss_id: exp.loss_id,
                        is_planned: exp.plannedLoss,
                        loss_duration: parseInt(exp.loss_time),
                        enabled: 1,
                        ct_id: form.values.partDayTarget?.ct_id

                    })
                }
            })

            oeeService.createLossData(payload)
                .then((res) => {
                    updateAlert({
                        open: true,
                        message: "Loss recorded Successfully",
                        type: "success",
                    });
                    closeForm();
                })
                .catch((error) => {
                    updateAlert({
                        open: true,
                        message: "Failed to record Losses",
                        type: "error",
                    });
                    let payload = {
                        id: uuidv4(),
                        error_description: error.message,
                        error_location: "createLossData",
                        client_id: currentUser.client_id,
                        user_id: currentUser.id,
                        entity_id: null,
                    };
                    errorLog.createErrorLog(payload)

                });
        }
    }


    return (

        <>
            <Grid container spacing={3}>
                <Grid item xs={8}>

                    {/* Production Date and time */}
                    <Grid container xs={12} direction="row" spacing={3}
                        style={{ "margin-bottom": "3px" }}>
                        <Grid item xs={3}>
                            <DGInput
                                label="Production Date"
                                value={format(form.values.productionDate, "dd/MM/yyyy")}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <DGInput
                                label=" Start Time"
                                value={form.values.productionStartTime}

                            />
                        </Grid>
                        <Grid item xs={2}>
                            <DGInput
                                label=" End Time"
                                value={form.values.productionEndTime}

                            />
                        </Grid>

                        <Grid item xs={5}>
                            <DGInput
                                label="Value Stream"
                                value={form.values.vsSelected}

                            />
                        </Grid>
                    </Grid>

                    {/*  Machine and Part */}
                    <Grid container xs={12} direction="row" spacing={3}
                        style={{ "margin-bottom": "3px" }}>


                        <Grid item xs={4}>
                            <DGInput
                                id="machine"
                                label="Machine"
                                value={form.values.machine}

                            />
                        </Grid>

                        <Grid item xs={3}>
                            <DGInput
                                id="operator"
                                label="Operator"
                                value={form.values.operator}

                            />
                        </Grid>

                        <Grid item xs={5}>
                            <DGInput
                                id="part"
                                label="Part"
                                value={form.values.part}

                            />
                        </Grid>
                    </Grid>

                    {/*Production Quantity and Rejections*/}
                    <Grid container xs={12} direction="row" spacing={3}
                        style={{ "margin-bottom": "3px" }}>


                        <Grid item xs={5}>
                            <DGInput
                                id="form.values.tool"
                                label="Form Tool"
                                value={form.values.tool}

                            />
                        </Grid>


                        <Grid item xs={2}>
                            <DGInput
                                label="Shots"
                                value={form.values.shots}

                            />
                        </Grid>



                        <Grid item xs={3}>
                            <DGInput
                                id="quantity"
                                label="Quantity"
                                value={form.values.quantity}
                            />
                        </Grid>


                        <Grid item xs={2}>
                            <DGInput
                                id="rejections"
                                value={form.values.rejections}
                            />
                        </Grid>

                    </Grid>

                    {/* OEE */}
                    <Grid container xs={12} direction="row" spacing={3}
                        style={{ "margin-bottom": "3px" }}>



                        <Grid item xs={3} >
                            <DGInput
                                id="target_production"
                                label="Target Quantity"
                                value={form.values?.oeeQuantity ?? ""}
                            />
                        </Grid>


                        <Grid item xs={3} >
                            <DGInput
                                id="loss"
                                label="Loss In minuted"
                                value={form.values?.partDayTarget?.time_loss_min ?? ""}
                            />
                        </Grid>

                        <Grid item xs={3} >
                            <DGInput
                                id="loss"
                                label="Loss Un-Classified"
                                value={form.values?.partDayTarget?.time_loss_min - form.values.cumTime}
                            />
                        </Grid>

                        <Grid item xs={3} >
                            <DGInput
                                id="loss"
                                label="Defects Un-Classified"
                                value={form.values.rejections - form.values.cumQuantity}
                            />
                        </Grid>

                    </Grid>

                    {/* Loss description */}
                    <Grid container xs={12} direction="row" spacing={2}>

                        <Grid item xs={3} >

                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                }}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={form2.values.plannedLoss}
                                            onChange={(e) => {
                                                form2.setFieldValue("plannedLoss", e.target.checked);
                                            }}
                                            name="plannedLoss"
                                            color="primary"
                                        />
                                    }
                                    label="Planned"
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: '14px',
                                    }}
                                />
                            </div>

                        </Grid>

                        <Grid item xs={7} >
                            <DGSelect
                                id="loss_description"
                                label="Loss Description"
                                value={form2.values.oeeLossSelected}
                                options={form.values.oeeLossOptions}
                                getOptionLabel={(option) =>
                                    option.loss_type + " - " + option.loss_description}
                                onChange={(val) => {
                                    form2.setFieldValue("oeeLossSelected", val);
                                }}
                            />
                        </Grid>

                        <Grid item xs={2}>
                            <DGInput
                                id="IncidentQuantity"
                                isFormik
                                label="Loss"
                                onChange={form2.handleChange}
                                value={form2.values.lossQuantity}

                            />
                        </Grid>

                    </Grid>


                    {/* Grid */}
                    <Grid container xs={12} direction="row" spacing={3}
                        style={{ "margin-bottom": "3px" }}>

                        <Grid item md={12} sm={12} lg={12}>
                            <CommonGrid
                                rows={rows}
                                columns={columns}
                                needExport={false}
                                gridheight={400}
                                onGridReady={onGridReady}
                            />
                        </Grid>

                    </Grid >

                </Grid>

                <Grid item xs={4}>
                    <Box
                        sx={{
                            border: '1px solid grey',
                            width: '98%',
                            padding: '5px',
                            marginTop: '5px',
                            height: '95%',
                            marginRight: '2%',
                            boxSizing: 'border-box',
                            overflowY: 'auto',
                            overflowX: 'auto',
                            height: "290px",
                        }}
                    >

                        <div style={{
                            marginBottom: '3px',
                            position: 'sticky',
                            top: '0',
                            zIndex: '100',
                            backgroundColor: 'white',
                            padding: '5px'
                        }}>

                            <Typography variant="body2"
                                style={{
                                    fontWeight: "bold",
                                    fontSize: "20px",
                                    textAlign: "center",
                                }}>

                                {"Update Loss"}

                            </Typography>

                        </div>

                        <div className={chipclasses.root}>
                            <>


                                <Chip
                                    label="Planned"
                                    onClick={() => form2.setFieldValue("plannedLoss", !form2.values.plannedLoss)}
                                    style={{
                                        backgroundColor: form2.values.plannedLoss
                                            ? transparentize(.2, '#BA4A00')
                                            : transparentize(0.01, '#BA4A00'),
                                        color: 'white',
                                    }}
                                />


                                {form.values.oeeChipOptions.map((item) => (
                                    <Chip
                                        key={item.id}
                                        label={item.loss_description}
                                        variant="outlined"
                                        onClick={() => form2.setFieldValue("oeeLossSelected", item)}
                                        style={{
                                            backgroundColor:
                                                item.loss_type_id === 15001 ? '#AED6F1' :
                                                    item.loss_type_id === 15002 ? '#FCF3CF' :
                                                        item.loss_type_id === 15003 ? '#FDEDEC' :
                                                            'transparent',  // Default color if no match

                                        }}
                                    />

                                ))}
                            </>
                        </div>
                    </Box>
                    <Box
                        sx={{
                            border: '1px solid grey',
                            width: '98%',
                            padding: '5px',
                            marginTop: '5px',
                            height: '95%',
                            marginRight: '2%',
                            boxSizing: 'border-box',
                            height: "245px",
                        }}
                    >
                        <div style={{
                            transform: 'scale(.97)',
                            transformOrigin: 'top',
                            display: 'inline-block'
                        }}>
                            <DialPadComponentOEE
                                measuredValue={handleMeasuredValue}
                                dailPadActive={dailPad}
                            />
                        </div>
                    </Box>
                </Grid>


                <AppBar position="fixed" style={{ width: 1200, top: "auto", bottom: 0 }}>
                    <Toolbar
                        style={{
                            display: 'flex',
                            justifyContent: 'space-evenly',
                            alignItems: 'center',
                            gap: '5px',
                        }}
                    >

                        {/* <Button
                            size="large"
                            variant="contained"
                            style={{
                                width: '25%',
                                backgroundColor: '#C0392B',
                                color: 'white',
                                fontWeight: 'bold',
                                fontSize: '15px',
                            }}
                            onClick={() => {
                                //   setActionStatus("close");
                                //   deleteAllLosses();
                            }}


                        >
                            Delete All losses
                        </Button> */}

                        <Button
                            size="large"
                            variant="outlined"
                            color="primary"
                            style={{
                                width: '25%',
                                backgroundColor: '#17A589',
                                color: 'white',
                                fontWeight: 'bold',
                                fontSize: '15px',
                            }}
                            onClick={() => {
                                // setActionStatus("restart");
                                // form.handleSubmit();
                                updateLoss()
                            }}
                        >
                            Update and Save
                        </Button>


                    </Toolbar>
                </AppBar>


            </Grid >



        </>

    );

}
export default ProductionRecordFormEdit;