import { Grid, Box, Paper, Tab, Tabs, withStyles, Button } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import CommonGrid from "../../../../components/CommonGrid"
import StdLossesListAdd from "./oee_stdLoss_add";
import { DGDrawer } from "../../../../components/DGDrawer";
import AddIcon from '@material-ui/icons/Add';
import oeeService from "../../../../services/oeeService";
import { ResultRenderer } from "../../../../components/cellRenderers/ResultRenderer";
import { AuthContext } from "../../../../context/AuthContextProvider";
import plantService from "../../../../services/plantService";
import { StatusContext } from "../../../../context/StatusContextProvider";


export default function ProductionLossesList() {
    const [rows, setRows] = useState([]);
    const [formOpen, setFormOpen] = useState(false);
    const [plantOptions, setPlantOptions] = useState([]);
    const { currentUser } = useContext(AuthContext);
    const { setStatus } = useContext(StatusContext);


    useEffect(() => {
        setStatus((prev) => ({
            ...prev,
            reloadGrid: reloadGridData,
            // callback: changeStatus,
        }));
        reloadGridData()
    }, []);

    useEffect(() => {
        plantService.getPlants(currentUser.client_id)
            .then((res) => {
                const temp = res?.data?.data ?? [];
                const temp1 = temp.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
                setPlantOptions(temp1);
            });
    }, []);

    function reloadGridData() {
        oeeService.getOEELossList(currentUser.client_id)
            .then((res) => {
                setRows(res.data);
            });
    }



    const static_columns = [

        {
            field: "serial_number",
            headerName: "SI No",
            valueGetter: "node.rowIndex + 1",
            filter: "agTextColumnFilter",
            maxWidth: 80,
        },

        {
            headerName: "Loss Category",
            field: "loss_type",
            filter: "agTextColumnFilter",
            minWidth: 150,
            editable: true,


        },


        {
            headerName: "Description",
            field: "loss_description",
            filter: "agTextColumnFilter",
            minWidth: 250,
            editable: true,


        }
    ]


    const plant_columns = [];
    for (let i = 0; i < plantOptions.length; i++) {
        const plantId = plantOptions[i].id;

        plant_columns.push({
            field: plantId,
            headerName: plantOptions[i].name,
            minWidth: 80,
            resizable: true,
            editable: true,
            cellRenderer: (params) => {
                const value = params?.data?.[plantId];
                if (value === 1) {
                    return <ResultRenderer value={1} />;
                }
                return null;
            }
        });
    }


    const columns = [...static_columns, ...plant_columns]



    function ButtonComponent() {
        return (
            <>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => setFormOpen(true)}
                >
                    Add Standard Loss
                </Button>

            </>
        );
    }


    return (

        <Grid container spacing={3}>

            <Grid item xs={12}>
                <CommonGrid
                    columns={columns}
                    rows={rows}
                    ButtonComponent={ButtonComponent}
                    gridheight={145}
                />


            </Grid>

            <DGDrawer
                Component={StdLossesListAdd}
                isOpen={formOpen}
                drawerWidth={1000}
                setOpen={(state) => {
                    setFormOpen(false);
                    reloadGridData()
                }}
                needSave
                title="Add New Loss"
            />

        </Grid>
    )


}