
import { Box, Grid, makeStyles, Button, Checkbox } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { DGDateInput } from "../../../components/shared/DGDateInput";
import { DGInput } from "../../../components/shared/DGInput";
import CommonGrid from "../../../components/CommonGrid";
import plantService from "../../../services/plantService";
import { AuthContext } from "../../../context/AuthContextProvider";
import { format } from 'date-fns';
import companySettings from "../../../services/companySettings";

export default function AddHoliday({
    Footer = () => null,
    closeForm = () => { },
}) {

    const [plantOptions, setPlantOptions] = useState([]);
    const [holiDate, setHoliDate] = useState(new Date());
    const [rows, setRows] = useState([]);
    const { currentUser } = useContext(AuthContext);
    const [gridApi, setGridApi] = useState(null);
    const [hDeescription, setHDescription] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);


    useEffect(() => {
        plantService.getPlants(currentUser.client_id)
            .then((res) => {
                const temp = res?.data?.data ?? [];
                const temp1 = temp.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
                setPlantOptions(temp1);
            });
    }, []);


    function onAdd() {
        rows.push({
            holiday_date: holiDate,
            holiday_name: hDeescription
        })
        gridApi.setRowData(rows);
    }


    function onGridReady(params) {
        setGridApi(params.api);
    }

    const CheckboxRenderer = (props) => {

        const isChecked = props.value === 1;

        const handleChange = (event) => {
            props.setValue(event.target.checked ? 1 : 0);
        };

        return (
            <input
                type="checkbox"
                checked={isChecked}
                onChange={handleChange}
            />
        );
    };

    const static_columns = [

        {
            field: "serial_number",
            headerName: "SI No",
            valueGetter: "node.rowIndex + 1",
            filter: "agTextColumnFilter",
            maxWidth: 60,
        },

        {
            headerName: "Date",
            field: "holiday_date",
            filter: "agTextColumnFilter",
            valueFormatter: (params) => {
                return format(new Date(params.value), 'dd/MM/yy EEEE');
            }
        },
        {
            headerName: "Description",
            field: "holiday_name",
            filter: "agTextColumnFilter",

        }
    ]

    const plant_columns = [];
    for (let i = 0; i < plantOptions.length; i++) {
        plant_columns.push({
            field: plantOptions[i].id,
            headerName: plantOptions[i].name,
            minWidth: 80,
            resizable: true,
            editable: true,
            cellRenderer: CheckboxRenderer

        });
    }


    const columns = [...static_columns, ...plant_columns]

    function handleSubmit() {
        const data = []
        gridApi.forEachNode((node) => {
            data.push({ ...node.data, client_id: currentUser.client_id });

        })

        const output = data.map(entry => {
            const plantIds = [];

            const transformedEntry = {
                holiday_date: format(new Date(entry.holiday_date), 'yyyy/MM/dd'),
                holiday_name: entry.holiday_name,
                client_id: entry.client_id,
                is_holiday: 1,
                created_by: currentUser.id,
            };

            for (const key in entry) {
                if (key.length > 10 && entry[key] === 1) {
                    plantIds.push(key);
                }
            }
            transformedEntry.plant_id = JSON.stringify(plantIds);

            return transformedEntry;
        });

        //   console.log(output);

        companySettings.createHolidayas(output)
            .then((res) => {
                //     console.log(res);
                closeForm();
            })
            .catch((err) => {
                //      console.log(err);
            });
    }

    function onRowSelection(data) {
        setSelectedRow(data);
    }



    function onDelete() {
        if (selectedRow) {
            const index = rows.findIndex((row) => row.evaluation_date === selectedRow.evaluation_date);
            rows.splice(index, 1);
            gridApi.setRowData(rows);
        }
    }



    return (

        <Grid container spacing={2}>

            <Grid xs={6} style={{ "padding": "5px" }}>
                <DGDateInput
                    variant="outlined"
                    label="Evaluation Date"
                    value={holiDate}
                    onChange={(value) => setHoliDate(value)}
                />
            </Grid>

            <Grid xs={6} style={{ "padding": "5px" }}>
                <DGInput
                    variant="outlined"
                    label="Description"
                    value={hDeescription}
                    onChange={(value) => setHDescription(value)}
                />
            </Grid>

            <Grid
                xs={3}
                style={{
                    marginTop: "7px",
                    display: "flex",
                    justifyContent: "center"
                }}
            >
                <Button
                    size="large"
                    variant="outlined"
                    color="primary"
                    style={{ padding: "7px 70px" }}
                    onClick={onAdd}
                >
                    ADD
                </Button>
            </Grid>

            <Grid
                xs={3}
                style={{
                    marginTop: "7px",
                    display: "flex",
                    justifyContent: "center"
                }}
            >
                <Button
                    size="large"
                    variant="outlined"
                    color="primary"
                    style={{ padding: "7px 70px" }}
                    onClick={onDelete}
                >
                    Delete
                </Button>
            </Grid>

            <Grid xs={12} style={{ "margin-top": "10px" }}>
                <CommonGrid
                    rows={rows}
                    columns={columns}
                    gridheight={212}
                    needExport={false}
                    onGridReady={onGridReady}
                    onRowSelect={onRowSelection}

                />
            </Grid>

            <Footer onSave={handleSubmit} />

        </Grid>

    )


}
