import { Box, Grid, makeStyles, Button } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { DGDrawer } from "../../../components/DGDrawer";
import CommonGrid from "../../../components/CommonGrid";
import { PlantContext } from "../../../context/PlantContextProvider";
import AddHoliday from "./add_holiday";
import AddIcon from "@material-ui/icons/Add";
import plantService from "../../../services/plantService";
import { AuthContext } from "../../../context/AuthContextProvider";
import { format } from 'date-fns';
import companySettings from "../../../services/companySettings";
import { StatusContext } from "../../../context/StatusContextProvider";
import { ResultRenderer } from "../../../components/cellRenderers/ResultRenderer";





export default function HolidayList() {
    const [formOpen, setFormOpen] = useState(false);
    const { setStatus } = useContext(StatusContext);
    const [rows, setRows] = useState([]);
    const [plantOptions, setPlantOptions] = useState([]);
    const [holiDate, setHoliDate] = useState(new Date());
    const { currentUser } = useContext(AuthContext);
    const [gridApi, setGridApi] = useState(null);
    const [hDeescription, setHDescription] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);



    useEffect(() => {
        setStatus((prev) => ({
            ...prev,
            reloadGrid: reloadGridData,
            callback: changeStatus,
        }));
        reloadGridData();
    }, []);

    useEffect(() => {
        plantService.getPlants(currentUser.client_id)
            .then((res) => {
                const temp = res?.data?.data ?? [];
                const temp1 = temp.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
                setPlantOptions(temp1);
            });
    }, []);

    function reloadGridData() {
        companySettings.getHolidays(currentUser.client_id)
            .then((res) => {
                setRows(res.data);
            });
    }

    function changeStatus(dummy1, dummy2, newStatus, rowId) {
        /*         return productionInspection.updateProductionInspectionRequest(rowId, {
                    enabled: newStatus,
                }); */
    }



    const static_columns = [

        {
            field: "serial_number",
            headerName: "SI No",
            valueGetter: "node.rowIndex + 1",
            filter: "agTextColumnFilter",
            maxWidth: 60,
        },

        {
            headerName: "Date",
            field: "holiday_date",
            filter: "agTextColumnFilter",
            valueFormatter: (params) => {
                return format(new Date(params.value), 'dd/MM/yy EEEE');
            }
        },
        {
            headerName: "Description",
            field: "holiday_name",
            filter: "agTextColumnFilter",

        }
    ]

    const plant_columns = [];
    for (let i = 0; i < plantOptions.length; i++) {
        const plantId = plantOptions[i].id;

        plant_columns.push({
            field: plantId,
            headerName: plantOptions[i].name,
            minWidth: 80,
            resizable: true,
            editable: true,
            cellRenderer: (params) => {
                const value = params?.data?.[plantId];
                if (value === 0) {
                    return <ResultRenderer value={0} />;
                }
                return null;
            }
        });
    }





    const columns = [...static_columns, ...plant_columns]


    function ButtonComponent() {
        return (
            <Button
                variant="outlined"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => {
                    setFormOpen(true);
                }}
                style={{ marginLeft: 3 }}
            >
                Add Holiday
            </Button>

        )

    }


    return (
        <Grid item md={12} sm={12} lg={12}>
            <CommonGrid
                rows={rows}
                columns={columns}
                gridheight={212}
                ButtonComponent={ButtonComponent}
            />
            <DGDrawer
                Component={AddHoliday}
                isOpen={formOpen}
                drawerWidth={800}
                title={"Add a Holiday"}

                setOpen={(state) => {
                    setFormOpen(false);
                    reloadGridData();
                }}
                needSave
            />

        </Grid>
    )

}