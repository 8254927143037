import { Fab, Grid, makeStyles, Typography, TextField } from "@material-ui/core";
import { useEffect, useContext, useState, } from "react";
import React from "react";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import { grey } from "@material-ui/core/colors";
import { DGInput } from "./shared/DGInput";
import { set } from "date-fns";


export default function DialPadComponentOEE({
  eachDial = () => { },
  submit = () => { },
  onClear = () => { },
  event = null,
  maskValue = 0,
  measuredValue = () => { },
  dailPadActive = false
}) {
  const [dailValue, setDialValue] = useState("");
  const [tempNumber, setTempNumber] = useState("");


  const useStyles = makeStyles({

    number: {
      backgroundColor: '#85C1E9',
      textAlign: "center"
    },

    smallText: {
      fontSize: "20px",
      marginRight: "5px",
      marginLeft: "5px"
    },

    dialValueDisplay1: {
      'font-weight': 'bold',
      'font-size': '25px',
      'text-align': 'right',
      padding: '3px',
    },
    dialValueDisplay2: {
      'font-weight': 'bold',
      'font-size': '23px',
      'text-align': 'right',
      'padding': '2px',
    },

  });

  const classes = useStyles();


  const renderDialPad = () => {
    var numbers = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "X", "C", "➔"];

    const rows = [
      numbers.slice(0, 5), // First row: 1-5
      numbers.slice(5, 10), // Second row: 6-0
      numbers.slice(10, 13), // Third row: X, C, ➔
    ];

    return (
      <Grid container spacing={2}>
        {rows.map((row, rowIndex) => (
          <Grid container item xs={12} spacing={1} key={rowIndex}>
            {row.map((number, index) => (
              <Grid
                item
                xs //={2.4}  Ensures each item is 1/5th of the row
                key={index}
                style={{ textAlign: "center", marginBottom: "5px" }}
                onClick={() => {
                  if (!dailPadActive) {
                    return;
                  }
                  dialClicked(number);
                }}
              >
                <Fab
                  variant="extended"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h2"
                    className={
                      number === "Clear" || number === "Next" || number === "Del"
                        ? classes.smallText
                        : ""
                    }
                  >
                    {number}
                  </Typography>
                </Fab>
              </Grid>
            ))}
          </Grid>
        ))}
      </Grid>
    );

  };


  const dialClicked = (number) => {
    if (number === "C") {
      setTempNumber("");

    }
    else if (number === "➔") {
      measuredValue(tempNumber)
      setTempNumber("");

    }
    else if (number === "X") {
      setTempNumber(tempNumber => tempNumber.slice(0, -1))
      if ((tempNumber.slice(0, -1)).length === 0) {
      }
    }
    else {

      setTempNumber(tempNumber.concat(number))
    }

  }



  return (
    <div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column"
    }}>

      <div style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}>


        <TextField
          variant="outlined"
          value={tempNumber}
          onChange={(e) => setDialValue(e.target.value)}
          marginBottom="10px"
          style={{
            padding: "10px",
            width: "50%",
            marginBottom: "7px",
          }}
          InputProps={{
            classes: {
              input: classes.dialValueDisplay2
            }
          }}
        />



      </div>

      <Grid container
        justifyContent="center" alignItems="center"
        style={{ marginBottom: "5px" }}
      >
        {renderDialPad()}
      </Grid>

    </div>
  );
}
