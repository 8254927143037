/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box, Button, Grid, TextareaAutosize, Checkbox, FormControlLabel,

} from "@material-ui/core";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { array, object, string } from "yup";
import { useParams } from "react-router-dom"
import { DeleteRenderer } from "../../../../components/cellRenderers/DeleteRenderer";
import CommonGrid from "../../../../components/CommonGrid";
import { ACTION_TYPE } from "../../../../components/shared/commonenum";
import { DGDateInput } from "../../../../components/shared/DGDateInput";
import { DGInput } from "../../../../components/shared/DGInput";
import { DGSelect } from "../../../../components/shared/DGSelect";
import { AuthContext } from "../../../../context/AuthContextProvider";
import { AlertContext } from "../../../../context/AlertContextProvider";
import { PlantContext } from "../../../../context/PlantContextProvider";
import productionInspection from "../../../../services/productionInspection";
import studyInputService from "../../../../services/studyInputService";
import productService from "../../../../services/productService";
import plantService from "../../../../services/plantService";
import formtoollist from "../../../../services/formTool";
import Loader from "../../../../components/Loader";
import errorLog from "../../../../services/errorLog";

export function PlanForm({
  Footer = () => { },
  componentProps = null,
  closeForm = () => { },
}) {
  const { currentUser } = useContext(AuthContext);
  const [GridApiContext, setGridApi] = useState(null);
  const { updateAlert } = useContext(AlertContext);
  const [plantID, setPlantId] = useState(null);
  const [partID, setPartId] = useState(null);
  const [toolFlag, setToolFlag] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const { v4: uuidv4 } = require("uuid");

  let id = useParams()

  let detailForm = useFormik({
    initialValues: {
      charactersticsOption: [],
      gaugeOptions: [],
      samples: "",
      tolerance: null,
      characterstics: null,
      gauge: null,
      measurement_options: null,
      process_number: "",

    },
    validationSchema: object({
      characterstics: object().required(),
      gauge: object().notRequired(),
      samples: string().required(),
      measurement_type: object().required(),
    }),
    onSubmit: handlePlanDetailsAdd,
  });

  let form = useFormik({
    initialValues: {
      plant: "",
      part: "",
      plan: "",
      instructions: "",
      createdby: "",
      createddate: "",
      gridData: [],
      measurement_options: [],
      toolOptions: [],
      tool: null,
      machineRelease: false
    },
    validationSchema: object({
      plan: string().required(),
      gridData: array().min(1),
    }),
    onSubmit: upsertForm,
  });


  useEffect(() => {
    if (componentProps?.formData?.id) {
      productionInspection
        .getInspectPlanView(componentProps?.formData?.id)
        .then((res) => {
          form.setFieldValue("gridData", res?.data?.body ?? []);
          form.setFieldValue("plan", res?.data?.header[0]?.plan_name);
          form.setFieldValue("tool", {
            tool_name: res?.data?.header[0]?.tool_name,
            tool_number: res?.data?.header[0]?.tool_number,
            machine_name: res?.data?.header[0]?.machine_name,
            machine_number: res?.data?.header[0]?.machine_number
          })
          form.setFieldValue("part",
            `${res?.data?.header[0]?.part_number}-${res?.data?.header[0]?.part_name}`
          )
          form.setFieldValue("plant", res?.data?.header[0]?.name,)
          setToolFlag(res?.data?.header[0]?.toolFlag,)
          setDataLoaded(true)
        });
      return () => {
        ACTION_TYPE.view = true
      }
    }
    else {
      ACTION_TYPE.view = false
    }
  }, [componentProps]);


  console.log(form.values.machineRelease)

  useEffect(() => {

    if (!ACTION_TYPE.view) {
      productService.getProductById(id.id)
        .then((res) => {
          setPlantId(res.data.plant_id)
          setPartId(id.id)
          form.setFieldValue("part", `${res.data?.part_number}-${res.data?.part_name}`)
            ("plant", res.data?.plant?.name)
        })
    }
  }, [])

  useEffect(() => {

    if (!ACTION_TYPE.view) {
      gettooldata()
    }
  }, [partID])

  useEffect(() => {

    if (!ACTION_TYPE.view) {
      if (form.values.toolOptions.length > 0) {
        setToolFlag(1)
      } else { setToolFlag(0) }
    }
  }, [form.values.toolOptions])

  useEffect(() => {

    if (!ACTION_TYPE.view) {
      loadCharacter();
      form.setFieldValue("createdby", currentUser?.name);
      loadMeasurementOptions();
    }
  }, [partID]);


  function gettooldata() {
    formtoollist.getToolProductList(
      currentUser?.client_id,
      plantID)
      .then((res) => {
        const tool_list1 = res.data
        const tool_list = tool_list1.filter(item => item.part_id === partID);
        form.setFieldValue("toolOptions", tool_list)
      });
  }


  function onGridReady(params) {
    setGridApi(params);
  }

  function loadCharacter() {
    productionInspection
      .getInspectionPlanChar(partID)
      .then((res) => {
        detailForm.setFieldValue("charactersticsOption", res?.data ?? []);
        setDataLoaded(true)
      });
  }

  if (!dataLoaded) {
    return <Loader />;
  }

  //(form.values)

  function loadGauge(id, flag) {
    productionInspection
      .getInspectionPlanCharGauge(id, flag, currentUser?.client_id)
      .then((res) => {
        detailForm.setFieldValue("gaugeOptions", res?.data ?? []);
      });
  }

  function loadMeasurementOptions() {
    studyInputService.getMeasurementType().then((res) => {
      form.setFieldValue("measurement_options", res?.data?.data ?? []);
    });
  }

  //        machine_release: form.values.machineRelease === true ? 1 : 0


  function upsertForm(values) {
    let payload = [];
    form.values.gridData.forEach((exp) => {
      payload.push({
        plant_id: plantID,
        part_id: partID,
        character_id: exp?.character_id,
        gauge_id: exp?.gauge_id,
        sample_size: exp?.sample_size,
        reduced_tolerance: exp?.reduced_tolerance,
        process_number: exp?.process_number,
        created_by: currentUser?.id,
        instructions: form.values.instructions,
        plan_name: form.values.plan,
        measurement_type: exp.measurement_type?.id,
        product_char_flag: exp?.product_char_flag,
        tool_product_id: form.values.tool?.id,

      });
    });


    productionInspection
      .createProductionInspectionPlan(payload)
      .then((res) => {
        updateAlert({
          open: true,
          message: "Plan Created Successfully",
          type: "success",
        });
        closeForm();
      })
      .catch((error) => {
        updateAlert({
          open: true,
          message: "Failed to create Plan",
          type: "error",
        });
        let payload = {
          id: uuidv4(),
          error_description: error.message,
          error_location: "createProductionInspectionPlan",
          client_id: currentUser.client_id,
          user_id: currentUser.id,
          entity_id: null,
        };
        errorLog.createErrorLog(payload)


      });
  }

  function removeDetailRow(rowIndex, data) {
    let plans = form.values.gridData;
    plans.splice(rowIndex, 1);
    GridApiContext.api.updateRowData({ remove: [data] });
  }

  const columns = [
    {
      cellRenderer: DeleteRenderer,
      minWidth: 80,
      cellRendererParams: {
        onClick: (data, rowIndex) => {
          removeDetailRow(rowIndex, data);
        },
      },
      hide: ACTION_TYPE.view,
    },

    {
      field: "serial_number",
      headerName: "#",
      valueGetter: "node.rowIndex + 1",
      filter: "agTextColumnFilter",
      maxWidth: 75,
      pinned: "left",
    },
    {
      field: "process_number",
      headerName: "Process Number",
      filter: "agTextColumnFilter",
      minWidth: 150,
    },
    {
      field: "name",
      headerName: "Characteristics",
      minWidth: 250,
      flex: 1,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
      // wrapText: true,
      autoHeight: true,
      //  tooltipShowDelay: 0,
      cellStyle: {
        whiteSpace: 'pre-wrap',
        lineHeight: '1.3' // 
      },
    },
    {
      field: "characterstics",
      headerName: "Specification",
      minWidth: 200,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
      wrapText: true,
      autoHeight: true,
      tooltipShowDelay: 0,
      tooltipValueGetter: (params) => `${params?.data?.name}`,
      valueGetter: (params) => {
        return ` ${params?.data?.specification ?? ""}  ${params?.data?.upper_specification ?? ""
          }  ${params?.data?.lower_specification ?? ""}`;
      },
    },
    {
      //field: "measurement_type_name",
      headerName: "Measurement",
      //field: "measurement_type.study",
      field: "measurement_type",
      minWidth: 150,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
      valueGetter: function (params) {
        return params.data.measurement_type?.study;
      },
    },
    {
      field: "gauge_name",
      headerName: "Gauge",
      minWidth: 150,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
      valueGetter: (params) =>
        params?.data?.gauge_number
          ? `${params?.data?.gauge_number}-${params?.data?.gauge_name}`
          : "",
    },
    {
      field: "sample_size",
      headerName: "Samples Nos",
      minWidth: 75,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
    },

    {
      field: "reduced_tolerance",
      headerName: "Reduced Tolerance(%)",
      minWidth: 75,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
    },
  ];

  function handlePlanDetailsAdd(values) {
    if (!detailForm.isValid) {
      return;
    }
    let data = form.values.gridData;
    let chars = detailForm.values.charactersticsOption;
    data = [
      ...data,
      {
        name: values?.characterstics?.character_name,
        lower_specification: values?.characterstics?.lower_specification,
        upper_specification: values?.characterstics?.upper_specification,
        specification: values?.characterstics?.specification,
        character_id: values?.characterstics?.character_id,
        gauge_name: values?.gauge?.name,
        gauge_number: values?.gauge?.number,
        gauge_id: values?.gauge?.gauge_id,
        sample_size: values?.samples,
        reduced_tolerance: values?.tolerance,
        type: values?.characterstics.type,
        measurement_type: values?.measurement_type,
        product_char_flag: values?.characterstics?.product_char_flag,
        process_number: values?.process_number,
      },
    ];
    form.setFieldValue("gridData", data);
    detailForm.resetForm();
    detailForm.setFieldValue("charactersticsOption", chars);
  }


  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={3} sm={3} lg={3}>
          <DGInput
            id="plant"
            label="Plant"
            disabled
            value={form.values.plant}
            onChange={form.handleChange}
            isFormik
          />
        </Grid>
        <Grid item md={4} sm={4} lg={4}>
          <DGInput
            id="part"
            label="Part Number"
            disabled
            value={form.values.part}
            onChange={form.handleChange}
            isFormik
          />
        </Grid>
        <Grid item md={3} sm={3} lg={3}>
          <DGInput
            id="plan"
            label="Plan Name"
            value={form.values.plan}
            onChange={form.handleChange}
            isFormik
            error={form.touched.plan && Boolean(form.errors.plan)}
          />
        </Grid>

        <Grid item md={2} sm={2} lg={2}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={form.values.machineRelease}
                  onChange={(e) => {
                    form.setFieldValue("machineRelease", e.target.checked);
                  }}
                  name="machineRelease"
                  color="primary"
                />
              }
              label="Machine Release"
              style={{
                fontWeight: 'bold',
                fontSize: '14px',
              }}
            />
          </div>
        </Grid>



        {toolFlag === 1 && (
          <Grid item md={6} lg={6} sm={6}>
            <DGSelect
              id="tool"
              label="Form tool"
              options={form.values.toolOptions}
              value={form.values.tool}
              onChange={(val) => {
                form.setFieldValue("tool", val);
              }}
              isFormik
              required
              getOptionLabel={(opt) =>
                `${opt?.tool_name} ${opt?.tool_number}  ${opt?.machine_number} ${opt?.machine_name}`
              }
              error={
                detailForm.touched.tool &&
                Boolean(detailForm.errors.tool)
              }
            />
          </Grid>
        )
        }
        {!ACTION_TYPE.view ? (
          <Grid container spacing={2} item md={12} sm={12} lg={12}>
            <Grid item md={12} lg={12} sm={12}>
              <Grid item md={6} lg={6} sm={6}>
                <Box fontWeight="fontWeightBold" m={1}>
                  Plan Details
                </Box>
              </Grid>

            </Grid>
            <Grid item md={8} lg={8} sm={8}>
              <DGSelect
                id="characterstics"
                label="Characteristics"
                options={detailForm.values.charactersticsOption}
                value={detailForm.values.characterstics}
                onChange={(val) => {
                  detailForm.setFieldValue("characterstics", val);
                  loadGauge(
                    val?.character_id,
                    val?.product_char_flag,
                    val?.character_type_id
                  );
                  detailForm.setFieldValue("gauge", "");
                }}
                isFormik
                required
                getOptionLabel={(opt) =>
                  `${opt?.character_name} ${opt?.specification !== null ? opt?.specification : ""
                  } ${opt?.upper_specification !== null
                    ? opt?.upper_specification
                    : ""
                  } ${opt?.lower_specification !== null
                    ? opt?.lower_specification
                    : ""
                  } `
                }
                error={
                  detailForm.touched.characterstics &&
                  Boolean(detailForm.errors.characterstics)
                }
              />
            </Grid>
            <Grid item md={2} lg={2} sm={2}>
              <DGInput
                id="samples"
                label="Number of Samples"
                value={detailForm.values.samples}
                onChange={detailForm.handleChange}
                isFormik
                required
              />
            </Grid>
            <Grid item md={2} lg={2} sm={2}>
              <DGInput
                id="tolerance"
                label="Reduced Tolerance"
                value={detailForm.values.tolerance}
                onChange={detailForm.handleChange}
                isFormik
                type="number"
              />
            </Grid>

            <Grid item md={4} lg={4} sm={4}>
              <DGInput
                id="process_number"
                label="Process #"
                value={detailForm.values.process_number}
                onChange={detailForm.handleChange}
                isFormik
              />
            </Grid>

            <Grid item md={4} lg={4} sm={4}>
              <DGSelect
                id="gauge "
                label="Gauge"
                options={detailForm.values.gaugeOptions}
                getOptionLabel={(opt) =>
                  opt?.number ? `${opt?.number}-${opt?.name}` : null
                }
                value={detailForm.values.gauge}
                onChange={(val) => detailForm.setFieldValue("gauge", val)}
                isFormik
              />
            </Grid>
            <Grid item md={4} lg={4} sm={4}>
              <DGSelect
                id="measurement_type"
                label="Measurement Type"
                getOptionLabel={(opt) => opt?.study}
                options={form.values.measurement_options}
                value={detailForm.values.measurement_type}
                onChange={(val) =>
                  detailForm.setFieldValue("measurement_type", val)
                }
                isFormik
                required
              />
            </Grid>
            <Grid item md={12} lg={12} sm={12}>
              <Button
                color="primary"
                variant="outlined"
                disabled={!detailForm.isValid}
                onClick={detailForm.handleSubmit}
                size="small"
              >
                Add
              </Button>
            </Grid>
          </Grid>
        ) : null}

        <Grid item md={12} sm={12} lg={12}>
          <CommonGrid
            rows={form.values.gridData?.length ? form.values.gridData : []}
            columns={columns}
            needExport={false}
            onGridReady={onGridReady}
            getRowStyle={(params) => {
              if (params?.data?.product_char_flag === 0) {
                return { backgroundColor: "#E5E7E9" };
              }
            }}
          />
        </Grid>
        <Grid item md={6} lg={6} sm={6}>
          <TextareaAutosize
            maxRows={2}
            className="w-100 border h-100"
            aria-label="maximum height"
            placeholder="Instructions"
            defaultValue=""
          />
        </Grid>

        <Grid item md={3} sm={3} lg={3}>
          <DGInput
            id="createdby"
            label="Created By"
            value={form.values.createdby}
            disabled
            isFormik
          />
        </Grid>
        <Grid item md={3} sm={3} lg={3}>
          <DGDateInput id="createddate" label="Created Date" disabled />
        </Grid>
      </Grid>
      <Footer onSave={form.handleSubmit} />
    </>
  );
}
