import { Box, Paper, Tab, Tabs, withStyles, } from "@material-ui/core";
import { PageTitleContext } from "../../../context/PageTitleContextProvider"
import { useContext, useEffect, useState } from "react";
import OEEDashboard from "./oee_dashboard";
import OEEStdLossesList from "./oee_standard_losses/oee_stdLoss_list";
import ProductionLossesList from "./oee_production_losses/oee_prdnLosses_list";
import OEELossList from "./oee_loss_list/oee_loss_list";

export default function OEETab() {

    const [selectedTab, setSelectedTab] = useState(0);
    const { setHeaderPageTitle } = useContext(PageTitleContext);

    const buttons = [
        "OEE",
        "Losses",
        "Standard Losses",
    ];

    useEffect(() => {
        setHeaderPageTitle(`OEE`);
    }, []);


    return (
        <Paper square>
            <Tabs
                value={selectedTab}
                indicatorColor="primary"
                textColor="primary"
                onChange={(e, val) => setSelectedTab(val)}
                aria-label="disabled tabs example"
            >
                {buttons.map((btn, index) => (
                    <StyledTab label={btn} value={index} />
                ))}
            </Tabs>
            {<Box m={2}>
                {selectedTab === 0 ? <ProductionLossesList /> : null}
                {selectedTab === 1 ? <OEELossList /> : null}
                {selectedTab === 2 ? <OEEStdLossesList /> : null}





            </Box>}
        </Paper>
    );
}

const StyledTab = withStyles((theme) => ({
    wrapper: {
        textTransform: "none",
    },
    root: {
        textTransform: "none",
    },
}))((props) => <Tab disableRipple {...props} />);