import { Button, Typography } from "@material-ui/core";
import React from "react";
import { useContext } from "react";
import { useState } from "react";
import ActiveInActive from "../../../../components/ActiveInActive";
import CommonGrid from "../../../../components/CommonGrid";
import { StatusContext } from "../../../../context/StatusContextProvider";
import { StatusHistoryContext } from "../../../../context/StatusHistoryProvider";
import AddIcon from "@material-ui/icons/Add";
import { DGDrawer } from "../../../../components/DGDrawer";
import { UnderInspectionForm } from "./UnderInspectionForm";
import { ResultRenderer } from "../../../../components/cellRenderers/ResultRenderer";
import moment from "moment";
import productionInspection from "../../../../services/productionInspection";
import { AuthContext } from "../../../../context/AuthContextProvider";
import { PlantContext } from "../../../../context/PlantContextProvider";
import { useEffect } from "react";
import StatusFilter from "../../../../components/shared/StatusFilter";
import { RequestForm } from "./RequestForm";
import { format, parseISO, parse } from 'date-fns';


export function UnderInspectionListing() {
  const [rows, setRows] = useState([]);
  const { setStatus } = useContext(StatusContext);
  const { setStatusHistory } = useContext(StatusHistoryContext);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRow_, setSelectedRow_] = useState(null);
  const [formOpen, setFormOpen] = useState(false);
  const [measureformOpen, setMeasureFormOpen] = useState(false);
  const [measureOpen, setMeasureOpen] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const { globalPlant } = useContext(PlantContext);
  const [subtitle, setSubtitle] = useState("");
  const [subtitle_, setSubtitle_] = useState("");

  useEffect(() => {
    setStatus((prev) => ({
      ...prev,
      reloadGrid: reloadgridData,
      callback: changeStatus,
    }));
    if (globalPlant && globalPlant?.globalPlant?.id) {
      reloadgridData();
    }
  }, []);

  useEffect(() => {
    reloadgridData()
  }, [globalPlant])

  function reloadgridData() {
    productionInspection
      .getInspectTablePlant({
        client_id: currentUser?.client_id,
        query_id: globalPlant?.globalPlant?.id,
        production_inspection_status: 2
      })
      .then((res) => {
        setRows(res?.data ?? []);
      });
  }

  function changeStatus(dummy1, dummy2, newStatus, rowId) {
    return productionInspection.updateProductionInspectionRequest(rowId, {
      enabled: newStatus,
    });
  }
  function onRowSelection(data) {
    setSelectedRow(data[0])
    setSubtitle(data[0].request_name ?? "");
  }


  function LoadRequestaForm(data) {
    setSubtitle(data?.request_name ?? "");
    setFormOpen(true);
  }

  function LoadMeasureForm(data) {
    //console.log(data)
    setSubtitle_(data.request_name ?? "");
    setSelectedRow_(data);
    setMeasureFormOpen(true);

  }


  function ButtonComponent() {
    return (
      <>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            LoadRequestaForm();
          }}
          style={{ marginLeft: 3 }}
        >
          REQUEST
        </Button>
        {/*
        {selectedRow ? (
          <Button
            variant="outlined"
            color="primary"
            startIcon={<KeyboardOutlinedIcon />}
            onClick={() => {
              LoadMeasureForm();
            }}
            style={{ marginLeft: 3 }}
          >
            MEASURE
          </Button> 
        ) : null}*/}
      </>
    );
  }



  const columns = [
    {
      headerName: "Sl No.",
      valueGetter: "node.rowIndex + 1",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 105,
      pinned: "left",
    },
    {
      field: "request_name",
      headerName: "Request Number",
      sortable: true,
      resizable: true,
      minWidth: "200",
      floatingFilter: true,
      autoHeight: true,
      filter: "agTextColumnFilter",
      cellRenderer: (params) => {
        return (
          <Button
            size="small"
            color="primary"
            onClick={() => LoadMeasureForm(params?.data)}>
            {`${params?.data?.request_name}`}
          </Button>
        );
      },
    },
    {
      field: "part_number",
      headerName: "Part Number",
      sortable: true,
      resizable: true,
      minWidth: "200",
      floatingFilter: true,
      autoHeight: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "plan_name",
      headerName: "Plan Name",
      sortable: true,
      resizable: true,
      minWidth: "200",
      floatingFilter: true,
      autoHeight: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "production_date",
      headerName: "Production Date",
      sortable: true,
      resizable: true,
      minWidth: "200",
      floatingFilter: true,
      autoHeight: true,
      filter: "agDateColumnFilter",
      filterParams: filterParams,
      valueGetter: (params) =>
        format(new Date(params.data.production_date), "dd/MM/yyyy")
    },
    {
      field: "production_time",
      headerName: "Production Time",
      sortable: true,
      resizable: true,
      minWidth: "150",
      floatingFilter: true,
      autoHeight: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "batch_details",
      headerName: "Batch Details",
      sortable: true,
      resizable: true,
      minWidth: "200",
      floatingFilter: true,
      autoHeight: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "productionDetails",
      headerName: "Production Record",
      sortable: true,
      resizable: true,
      minWidth: "200",

      cellRenderer: (params) => {
        if (!params?.data?.production_date) {
          return null;
        }

        const formattedDate = format(parseISO(params.data.production_date), 'dd/MM/yy');
        const formattedTime = params.data.production_start_time
          ? format(parse(params.data.production_start_time, 'HH:mm:ss', new Date()), 'HH:mm')
          : '';

        return (
          <>
            <Typography variant="body2" style={{ marginBottom: '-5px' }}>
              {`PREC/${params?.data?.record_counter}`}
            </Typography>

            <Typography variant="body2">
              {formattedDate} {formattedTime}
            </Typography>
          </>
        );
      }

    },

    {
      field: "enabled",
      headerName: "Status",
      minWidth: 200,
      filter: "agNumberColumnFilter",
      floatingFilter: true,
      suppressMenu: true,
      floatingFilterComponent: StatusFilter,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: (params) => (
        <ActiveInActive
          status={params?.data?.enabled}
          onClick={() =>
            setStatus((prev) => ({
              ...prev,
              open: true,
              entityName: params?.data?.request_name,
              currentStatus: params?.data?.enabled,
              rowId: params.data.request_id,
            }))
          }
        />
      ),
    },
    {
      field: "history",
      headerName: "Status History",
      minWidth: 200,
      cellRenderer: (params) => {
        return (
          <Button
            onClick={() =>
              setStatusHistory((prev) => ({
                ...prev,
                isOpen: true,
                rowId: params.data.id,
                entity: params?.data?.plan_name ?? "",
              }))
            }
            size="small"
            color="primary"
          >
            View
          </Button>
        );
      },
    },
  ];



  return (
    <>
      <CommonGrid
        rows={rows}
        columns={columns}
        gridheight={115}
        onRowSelect={onRowSelection}
        ButtonComponent={ButtonComponent}
      />

      <DGDrawer
        Component={RequestForm}
        title="Production Inspection - Request"
        isOpen={formOpen}
        setOpen={(state, reqID) => {
          if (reqID) {
            setFormOpen(state);
            setSelectedRow_({ request_id: reqID });
            setMeasureFormOpen(true);
          } else {
            setFormOpen(state);
          }
          reloadgridData();
        }}
        subtitle={subtitle}
        drawerWidth={1150}
        needSave
        gembaScreen={0}
        componentProps={{ source: 0 }}
      />
      <DGDrawer
        Component={UnderInspectionForm}
        title="Production Inspection - Measure"
        isOpen={measureformOpen}
        setOpen={(state) => {
          setMeasureFormOpen(state);
          // setSubtitle("");
          reloadgridData();
        }}
        subtitle={subtitle}
        componentProps={selectedRow_}
        drawerWidth={1200}
        needSave
      />
    </>
  );
}

var filterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    var dateParts = dateAsString.split("/");
    var cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0])
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  browserDatePicker: true,
};
