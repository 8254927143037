import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  Paper,
  Tab,
  Tabs,
  withStyles,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PageTitleContext } from "../../../context/PageTitleContextProvider"
import { ProductMachine } from "./productMachine"
import MachineProductTable from "./machineProductTable"
import MachineProductCycleTimeList from "./machineProductCycle/machineProductCycleList"

function MachineProductList({ }) {
  const [selectedTab, setSelectedTab] = useState(0);
  const { setHeaderPageTitle } = useContext(PageTitleContext);

  const buttons = [
    "Product Machine",
    "Machine Product",
    "Cycle Time",
  ];

  useEffect(() => {
    setHeaderPageTitle(`Machine ~ Product`);
  }, []);

  return (
    <Paper square>
      <Tabs
        value={selectedTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={(e, val) => setSelectedTab(val)}
        aria-label="disabled tabs example"
      >
        {buttons.map((btn, index) => (
          <StyledTab label={btn} value={index} />
        ))}
      </Tabs>
      <Box m={2}>
        {selectedTab === 0 ? <ProductMachine /> : null}
        {selectedTab === 1 ? <MachineProductTable /> : null}
        {selectedTab === 2 ? <MachineProductCycleTimeList /> : null}

      </Box>
    </Paper>
  );
}

export default MachineProductList;

const StyledTab = withStyles((theme) => ({
  wrapper: {
    textTransform: "none",
  },
  root: {
    textTransform: "none",
  },
}))((props) => <Tab disableRipple {...props} />);
