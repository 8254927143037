import React from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import { format, setHours, setMinutes } from 'date-fns';


export default function HourChips({
  blockfrom = '',
  onTimeSelect = () => { },
  endTime = "",
  shiftStart = 0,
  shiftEnd = 0,
  shiftName = '',
  firstShift = 0

}) {

  const useStyles = makeStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '17px',
      marginBottom: '5px',
    },
    chip: {
      fontSize: '15px',
      padding: '9px',
      textAlign: 'center',
      width: 'fit-content',
      marginBottom: '5px',
    },
    chipPM: {
      backgroundColor: '#FFB6C1',
    },
    chipAM: {
      backgroundColor: '#ADD8E6',
    },
  });

  const classes = useStyles();
  const hours__ = Array.from({ length: 24 }, (_, index) => index + 0);

  let hours = []
  if (shiftName === 'General') {
    hours = [...hours__.slice(firstShift), ...hours__.slice(0, firstShift + 1)];

  }
  else {
    if (shiftStart <= shiftEnd) {
      hours = hours__.slice(shiftStart, shiftEnd + 1);
    } else {
      hours = [
        ...hours__.slice(shiftStart),
        ...hours__.slice(0, shiftEnd + 1)
      ];
    }

  }

  const handleClick = (hour, period) => {
    const date = new Date();
    const hoursIn24 = period === 'PM' ? (hour % 12) + 12 : hour % 12;
    const formattedDate = format(
      setMinutes(setHours(date, hoursIn24), 0),
      'HH:mm'
    );

    onTimeSelect(formattedDate);
  };


  return (
    <div className={classes.root}>

      {hours.map(hours => (

        <Chip
          key={`${hours}`}
          label={`${hours > 12 ? hours - 12 : hours === 0 ? 12 : hours} ${hours < 12 ? 'AM' : 'PM'}`}
          variant="outlined"
          className={`${classes.chip} ${hours < 12 ? classes.chipAM : classes.chipPM}`}
          onClick={() => handleClick(hours, hours < 12 ? 'AM' : 'PM')}
        // disabled={hours <= blockfrom && blockfrom}
        />


      ))}

    </div>
  );
};

