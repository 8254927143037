import { Box, Paper, Tab, Tabs, withStyles } from "@material-ui/core";
import React, { useState } from "react";
import Settings from "./Settings";
import HolidayList from "./holiday_list/holiday_list";
import ShiftManagementList from "./shift_management/shift_management_list";



export function SettingTab({ }) {
    const [selectedTab, setSelectedTab] = useState(0);
    const buttons = ["Company Details", "Holidays", "Shift Management"];
    return (
        <Paper square>
            <Tabs
                value={selectedTab}
                indicatorColor="primary"
                textColor="primary"
                onChange={(e, val) => setSelectedTab(val)}
                aria-label="disabled tabs example"
            >
                {buttons.map((btn, index) => (
                    <StyledTab label={btn} value={index} />
                ))}
            </Tabs>
            <Box m={2}>
                {selectedTab === 0 ? <Settings /> : null}
                {selectedTab === 1 ? <HolidayList /> : null}
                {selectedTab === 2 ? <ShiftManagementList /> : null}
            </Box>
        </Paper>
    );
}

const StyledTab = withStyles((theme) => ({
    wrapper: {
        textTransform: "none",
    },
    root: {
        textTransform: "none",
    },
}))((props) => <Tab disableRipple {...props} />);