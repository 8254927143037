import { create } from "jss";
import http from "./httpService";

const createLossList = async (data) => {
    return await http.post("/oee-loss-list", data);
}

const getOEELossList = async (client_id) => {
    return http.get("/oee-loss-table", {
        params: {
            client_id: client_id,
        },
    });
};



const getOEETable = (data) => {
    return http.get("/oee-table", { params: { ...data } })
};

const getPartDayTarget = (data) => {
    return http.get("/oee-part-day", { params: { ...data } })
}

const createLossData = async (data) => {
    return await http.post("/oee-loss-record", data);
}

const getPrdouctionIDLoss = (data) => {
    return http.get("/oee-loss-production", { params: { ...data } })
}


const updateProductionRejections = (id, data) => {
    return http.patch(`/defects-record/${id}`, data);
}


const updateProductionLoss = (id, data) => {
    return http.patch(`/oee-loss-record/${id}`, data);
}

const getLossProductionTable = (data) => {
    return http.get("/oee-lossrecord-table", { params: { ...data } })
}


const getOEELossesForOEE = (data) => {
    return http.get("/losses-for-oee", { params: { ...data } })
}

const getOEELossesForPlant = (data) => {
    return http.get("/losses-plant", { params: { ...data } })
}



export default {

    createLossList,
    getOEELossList,
    getOEETable,
    getPartDayTarget,
    createLossData,
    getPrdouctionIDLoss,
    updateProductionRejections,
    updateProductionLoss,
    getLossProductionTable,
    getOEELossesForOEE,
    getOEELossesForPlant
}